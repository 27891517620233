import React, { useState, useEffect } from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
// import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import useAuth from "../../hooks/useAuth";
import axiosMain from "../../http/axios/axios_main";
import axios from "axios";
import ReactPaginate from "react-paginate";
import axiosNew from './../../http/axios/axios_new';
import { Button, Col, Container, Form, FormLabel, Row } from "react-bootstrap";
import helpers from "../../Helper/CryptoHelper";

const Patients_List = () => {
    const { user, isAuthenticated } = useAuth();

    const [patients, setpatients] = useState({
        isloading: false,
        data: [],
        error: "",

    });

    const [patients_data, setpatients_data] = useState("");

    const [diagnos, setdiagnos] = useState([]);

    const [vlepatients, setvlepatients] = useState(0);
    const [vleappoitments, setvleappoitments] = useState(0);
    const [vlebalance, setvlebalance] = useState(0);
    const [vleTodayAppointment, setVleTodayAppointment] = useState(0);

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 30;
    const pageVisited = pageNumber * usersPerPage;

    const [searchTerm, setsearchTerm] = useState("");

    const [reportshow, setreportShow] = useState(false);

    const getData = async () => {

        try {

            setpatients((prev) => {
                return {
                    ...prev,
                    isloading: true,
                }
            })

            let inputdata = {
                user_id: user.id,
            };

            let res = await axiosMain.post("/api/vle-patients", helpers.encrypt(JSON.stringify(inputdata)));
            //console.log(JSON.parse(res), "sir data");
            res.data = helpers.decrypt(JSON.stringify(res.data));
            // res=await JSON.parse(res.data.patients)
            console.log("patient list--n", res.data.original.patient);
            if (res.data.original.status == "1") {
                // setpatients(res.data.patient);

                setpatients({
                    isloading: false,
                    data: res.data.original.patient,
                    error: "",
                });

                // setpatients_data();
            }


        } catch (error) {

            setpatients({
                isloading: false,
                data: [],
                error: error.data,
            });
        }

    };
    const handletopup1 = async (data) => {
        setreportShow(true);


        const inputdata_report = {

            patient_id: data
        }
        let res_report = await axiosNew.post("/api/vle/patient/report/list", inputdata_report);


        console.log(res_report, 'res_reportres_reportres_reportres_report');
        if (res_report.data.status == "1") {



            setdiagnos(res_report.data.data);
        }


    };
    useEffect(() => {
        getData();
    }, []);


    console.log("patients---", patients);
    const pageCount = Math.ceil(patients.data.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
        window.scrollTo(0, 0);

    }


    console.log(patients, 'pl');

    const reporthandleClose = () => {
        setreportShow(false);
    };

    console.log(diagnos, 'vvcc');
    return (
        <Dashboard_layout>
            <div className="content-top-padding">
                {/* <div className=" pt-2 pb-4">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i class="fas fa-hospital"></i>
                <span>{vleTodayAppointment}</span>
                <h6>Today's Appointments</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i class="fas fa-user"></i>
                <span>{vlepatients}</span>
                <h6>Total Patients</h6>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i class="fas fa-user"></i>
                <span>{vleappoitments}</span>
                <h6>Total Appointment</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i class="fas fa-repeat-1-alt"></i>
                <span>{vlebalance}</span>
                <h6>Total Balance</h6>
              </div>
            </div>
          </div>
        </div> */}
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary mt-2">
                            Patients Report
                        </h6>
                    </div>
                    <div className="card-body">

                        <div className="row">
                            <div class="col-sm-6">
                                {/* <div className="drop-flex">
                  <p>Show </p>
                  
                  <select onChange={formdata}
                    name="pagecount"
                    value={inputdata.pagecount}
                    class="form-select"
                    aria-label="Default select example"
                  >
              
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="300">300</option>
                  </select>

                  <p>entries</p>
                </div> */}
                            </div>
                            {/* <div class="col-sm-6">
                <div className="drop-flex">
                  <label>Search:</label>
                  <input
                  style={{marginBottom:10}}
                    onChange={(event) => { setsearchTerm(event.target.value) }}
                    type="search"
                    class="form-control input-sm"
                    placeholder="Search by name..."
                    aria-controls="appoinment_table"
                  ></input>
                </div>
              </div> */}
                  <div class="col-sm-6">
                <div className="drop-flex">
                  <label>Search:</label>
                  <input
                  style={{marginBottom:10}}
                    onChange={(event) => { setsearchTerm(event.target.value) }}
                    type="search"
                    class="form-control input-sm"
                    placeholder="Search by name..."
                    aria-controls="appoinment_table"
                  ></input>
                </div>
              </div>
                        </div>

                        <div className="table-responsive">
                            <table
                                className="table table-bordered vle-table"
                                id="dataTable"
                                width="100%"
                                cellSpacing={0}
                            >
                                <thead>
                                    <tr>
                                        <th>Patient Name</th>
                                        <th>Contact</th>
                                        {/* <th>Patient Age</th>
                    <th>Address</th> */}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {patients.isloading && (
                                        <tr>
                                            <td
                                                style={{ textAlign: "center", color: "red" }}
                                                colspan="5"
                                            >
                                                Data loading
                                            </td>
                                        </tr>
                                    )}

                                    {!patients.data.isloading && patients.data &&
                                        patients.data?.filter((items) => {
                                            if (searchTerm == "") {
                                                return items;
                                            } else if (items.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return items;
                                            }
                                        })?.slice(pageVisited, pageVisited + usersPerPage).map((items, key) => {
                                            return (
                                                <tr>
                                                    <td>{items.name}</td>
                                                    <td>{items.mobile}</td>
                                                    <td>
                                                        <a
                                                            onClick={(e) => handletopup1(items.id)}
                                                            //   onClick={(e) => createinvoice(items)}
                                                            class="btn btn-light-secondary btn-sm"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="View"
                                                            placeholder="Reports"
                                                        >
                                                            <i class="far fa-eye"></i>
                                                        </a>
                                                    </td>

                                                </tr>
                                            );
                                        })}


                                </tbody>
                            </table>

                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                className="appointment-modal"
                show={reportshow}
                onHide={reporthandleClose}
                animation={false}
                centered
            >
                <Modal.Header className="head-modal1" closeButton>
                    <Modal.Title> Uploaded Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <Row>
                            <div className="pb-3" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                <Row>


                                    {diagnos != '' ? diagnos.map((items, index) => {
                                        return (

                                            <Col md="6" sm="6">
                                                <div className="modal-box pb-3 ">
                                                    {/* <h6>{items.name}</h6> */}
                                                    <img className="image-background " src={items.report_file} height="100%" width="100%" />
                                                    <a href={items.report_file} target="_blank">
                                                    <Button className="btn btn-primary">view</Button>
                                                    </a>
                                                
                                                </div>
                                            </Col>


                                        );
                                    }) : <h5>No Report Uploaded</h5>}
                                </Row>
                            </div>

                        </Row>

                        <Container>
                            <div
                                className="justify-content-center"
                                style={{ display: "flex" }}
                            >
                                <Button
                                    className="btn btn-primary margin-btn"
                                    onClick={() => setreportShow(false)}
                                >
                                    Close
                                </Button>
                                {/* <Button
                      className="btn btn-primary margin-btn"
                      type="button"
                      onClick={() => HandleSubmit()}
                    >
                      Submit
                    </Button> */}
                            </div>
                        </Container>
                    </div>
                </Modal.Body>
            </Modal>
        </Dashboard_layout>
    );
};

export default Patients_List;
