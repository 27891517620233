import React, { useState } from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useLocation } from "react-router";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";

export const Abhaotp = () => {
  const navigate = useNavigate();

  const { state } = useLocation("/abha-validate");
  const [adharprocess, setadharprocess] = useState(false);

  const { txnid } = state;
  const { token } = state;

  const { type } = state;
  const { authMethod } = state;



  const [OTP, setOTP] = useState("");
  var apiendpoint;
  const handlesubmit = async () => {
    if (type == 1) {
      apiendpoint = 'confirmaadharotp';
    } else {
      apiendpoint = "confirmmobileotp"
    }
    const payloadfinal = {
      otp: OTP,
      txnId: txnid,
    }
    setadharprocess(true);

    try {
      const adharapifinal = await axios.post(`https://www.lms.extreme.org.in/api/${apiendpoint}`, payloadfinal, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })

      console.log(adharapifinal, 'innerapifinal');

      if (adharapifinal.data.status == 1) {
        navigate('/abha-adhar-png', { state: { token: token, xtoken: adharapifinal.data.data } })

      } else {
        setadharprocess(false);

        toast.error(adharapifinal.data.message);
      }
    } catch (error) {
      console.log(error)
      setadharprocess(false);

    }
  }
  const handleresentotp = async () => {
    // setadharprocess(true)
    try {
        const apiresult = await axios.post('https://www.lms.extreme.org.in/api/resendauth', {
            txnId: txnid,
            authMethod:authMethod,
        }, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        console.log(apiresult, 'apiresultapiresult');
        if (apiresult.data.status == 1) {
            toast.success(apiresult.data.message);
            // setadharprocess(false)

        } else {
            toast.error(apiresult.data.message);
            // setadharprocess(false)
        }
    } catch (error) {
        console.log(error);
        // setadharprocess(false)
    }
}

  return (
    <div>
      <Dashboard_layout>
        <ToastContainer />

        <div className="content-top-padding">
          <div className="card shadow mb-4">
            <div className="abha-number-section">

              <div>
                <div className="abha-inner-info step-2-section">
                  <div className="otp-info d-flex justify-content-center text-center">
                    {/* <p>We Just sent an OTP on the Mobile Number ******3436 linked with your Aadhaar</p> */}
                    <div className="otp-code-block">
                      <p>Enter OTP-{authMethod}</p>
                      <div className="otp-code-inner">
                        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure separator={<span>-</span>}
                        />
                        <ResendOTP
                          style={{ marginTop: 15 }}
                        // maxTime={120} 
                        onResendClick={() => handleresentotp()} 

                        />
                        <div className="resend-otp">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button-container" id="otp_verify">
                    <button className="btn btn-next"
                    disabled={adharprocess == true ? true : false}
                      onClick={() => handlesubmit()}
                      

                    >{adharprocess == true ? 'Processing' : 'Next'}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard_layout>
    </div>
  );
};
