import React from 'react'
import { Button, Col, Container, Form, FormControl, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap";
import Dashboard_layout from "../Layout/Dashboard_layout";
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import axiosNew from './../../http/axios/axios_new';
// import { useState } from 'react';

export const Appointment_view = () => {
    const { user, isAuthenticated } = useAuth();

    const [data, setdata] = useState({
        isloading: false,
    });

    useEffect(() => {

        if (state != null) {
            window.scrollTo(0, 0);
            toast.success('Appointment Created successfully');
            toast.error('Health scan is pending');

        }

    }, [])

    const { state } = useLocation("/dashboard");

    const { appointid } = state;

    const [apidata, setapidata] = useState("");
    const [apidatapatient, setapidatapatient] = useState();

    useEffect(() => {


        getData();
      

    }, [])


    const getData = async () => {

      
        let res = await axiosNew.post("/api/vle-appointment-details", {
            id: appointid,

        });

        //   const finaldata= await 
        console.log(res, 'mlmlmlmlmlmlmlm')

        if (res.data.status == 1) {

            setapidata(res.data.appointment);

            setapidatapatient(res.data.patient);

        }

     
    }

    const scancall = async () => {

        
        setdata((prev) => {
            return {
                ...prev,
                isloading: true,

            }

        });

        const res = await axiosNew.get(`/api/last-health-frame?appointment_id=${appointid}&vle_id=${user.id}`);


        console.log(res.data.status, 'deviceresponse');
        if (res.data.status == 1) {

            // window.location.reload();

            getData();
        }else{
            toast.warn(res.data.msg);
        }
        setdata({
            isloading: false,

        });


    }
    return (
        <Dashboard_layout>

            <ToastContainer />
            <div className="content-top-padding">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary mt-2 pull-left">
                            Appointments
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <Col md="12">
                                <div className='for-border-full'>

                                    <div className='header-details1'>
                                        <table className='for-view1'>
                                            <tr>
                                                <td>Patient Name :</td>
                                                <td><span> {apidatapatient != null ? apidatapatient.name : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Appointment Date :</td>
                                                <td><span>{apidata != null ? apidata.date : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Appointment Status :</td>
                                                <td><span>pending</span></td>
                                            </tr>
                                            <tr>
                                                <td>Age :</td>
                                                <td><span>{apidatapatient != null ? apidatapatient.age : ''}</span></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className='mt-2 header-details-body'>
                                        <div className='history-head d-flex'>
                                            <h5 className='m-0 font-weight-bold mt-2 pull-left text-primary'>History</h5>
                                            <a  onClick={() => scancall()} className={data.isloading ? 'disabled-link_scan btn btn-primary pull-right' : 'btn btn-primary pull-right'} style={{ backgroundColor: data.isloading ? 'green': '#053479', }}>{data.isloading==true ?   'Please wait' : 'Check Scan'}</a>
                                        </div>
                                        <table className='for-view1 mt-1'>
                                            <tr>
                                                <td>Temperature :</td>
                                                <td><span>{apidata != null ? apidata.t : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Pulse Rate :</td>
                                                <td><span>{apidata != null ? apidata.p : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Respiratory Rate :</td>
                                                <td><span>{apidata != null ? apidata.r : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Blood Pressure(BP) :</td>
                                                <td><span>{apidata != null ? apidata.bp : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Height :</td>
                                                <td><span>{apidata != null ? apidata.ht : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Weight :</td>
                                                <td><span>{apidata != null ? apidata.wt : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>SPO2 :</td>
                                                <td><span>{apidata != null ? apidata.spo2 : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Chief complaint :</td>
                                                <td><span>{apidata != null ? apidata.chief_complains : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Menstrual History :</td>

                                                <td><span>{apidata != null ? apidata.med_histry : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Past History :</td>
                                                <td><span>{apidata != null ? apidata.past_history : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Personal History :</td>
                                                <td><span>{apidata != null ? apidata.personal_history : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Provisional Diagnosis :</td>
                                                <td><span>{apidata != null ? apidata.prov_diagn : ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Allergies :</td>
                                                <td><span>{apidata != null ? apidata.allergies : ''}</span></td>
                                            </tr>
                                        </table>
                                    </div>

                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </Dashboard_layout>
    )
}
