import React, { useState, useEffect } from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import axiosMain from "../../http/axios/axios_main";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from "react-toastify";
import axiosNew from './../../http/axios/axios_new';
import helpers from "../../Helper/CryptoHelper";
const Transaction_Details = () => {
  const { user, isAuthenticated } = useAuth();
  const [wallet_tran, setwallet_tran] = useState(false);
  // const [balance_patient, setbalance_patient] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 20;
  const pageVisited = pageNumber * usersPerPage;


  const [inputdatacount, setinputdatacount] = useState({
    start: '',
    end: ''

  });

  const formdatacount = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputdatacount({ ...inputdatacount, [name]: value });
  };


  const getData = async () => {




    try {
      let inputdata = {
        user_id: user.id,
        limit: 100,
        offset: 0,
      };
      console.log("zcsvfdv");
      let res = await axiosNew.post("/api/vle/wallet", helpers.encrypt(JSON.stringify(inputdata)));
      // let res = await axios.post("https://api.medyseva.com/api/vle/wallet", inputdata);

      res.data = helpers.decrypt(JSON.stringify(res.data));
      console.log("zcsvfdv", res.data.original.data.history);
      if (res.data.original.status == "1") {
        setwallet_tran(res.data.original.data.history);
        // setbalance_patient(res.data.data.wallet.amount);
      }
    } catch (error) {


    }


  };
  useEffect(() => {
    getData();
  }, []);


  const downloadexcel = () => {

    if (inputdatacount.start && inputdatacount.end != '') {
      
      // axios.post("https://api.medyseva.com/api/vle/wallet/export", {

        axiosNew.post("/api/vle/wallet/export", {

        vle_id: user.id,
        start_date: inputdatacount.start,
        end_date: inputdatacount.end

      },
        {
          responseType: "blob"
        })
        .then(response => {
          let blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        });

    } else {

      toast.warn('Please enter Valid date');

    }


  }


  const pageCount = Math.ceil(wallet_tran.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo(0, 0);

  }
console.log(pageVisited+usersPerPage,'io');
  return (
    <Dashboard_layout>
      <ToastContainer />

      <div className="content-top-padding">
        <section className="card-outer-box">
          <div className="inner-content">
            <form>
              <div className="row">
                <div className="col-md-4 mb-1">
                  <div className="input-group date" id="datepicker">
                    <input type="date" className="form-control" name="start" value={inputdatacount.start} onChange={formdatacount} id="date" />
                  </div>
                </div>
                <div className="col-md-4 mb-1">
                  <div className="input-group date" id="datepicker">
                    <input type="date" onChange={formdatacount} name="end" value={inputdatacount.end} className="form-control" id="date" />
                  </div>
                </div>
                <div className="col-md-4 mb-1">
                  <button type="button" className="btn btn-primary" onClick={downloadexcel}>
                    Export To Excel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary mt-2">
              Transaction History
            </h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table
                className="table table-bordered vle-table"
                id="dataTable"
                width="100%"
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Transaction Id</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Remark</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Wallet Amount</th>
                    <th>Date</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>

                  {wallet_tran &&
                    wallet_tran?.slice(pageVisited, pageVisited + usersPerPage)?.map((items, key) => {
                      return (
                        <tr>
                          <td>{items.id}</td>
                          <td>{items.trx_id}</td>
                          <td>{items.from_wallet_name}</td>
                          <td>{items.to_wallet_name}</td>
                          {/* <td>{items.category}</td> */}
                          <td>
                          {items.category}
                              <br />
                              <small className="text-black">
                              Patient :  <b>{items.patient != null ? items.patient.name.substring(0, 10) ? items.patient.name.substring(0, 20) : '' : ''}</b>
                              </small>
                            </td>
                          <td>{items.from_wallet == user.wallet_id ? 'Debit' : 'Credit'}</td>
                          <td>{items.amount}</td>
                          <td>{items.from_wallet === user.wallet_id ? items.current_amount : items.receiver_amount}</td>
                          <td>{moment(items.created_at).format("YYYY-MM-DD")}</td>

                        </tr>
                      );
                    })}

                </tbody>
              </table>


            </div>


            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
    </Dashboard_layout>
  );
};

export default Transaction_Details;
