import React from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import { useLocation } from "react-router";

export const AbhaPreview = () => {

  const { state } = useLocation("/abha-mobileotp");
 
  const { detail } = state;



  console.log(detail,'detaildetaildetaildetaildetailvvv')
  return (
    <div>
      <Dashboard_layout>
        <div className="content-top-padding">
          <div className="card shadow mb-4">
            <div>
              <div className="abha-number-section">
                <div className="">
                  <div className="abha-inner">
                    <div className="abha-heading">
                      <h2>Login to your ABHA number</h2>
                    </div>
                    <hr></hr>
                  
                    <div className="login-inner">
                      <h6>We found the following ABHA number linked to this number<br></br>Select the ABHA number for which you wish to Login</h6>
                      
                    
                     <div className="preview-list">
                        <ul>
                            <li><b>Name </b>: {detail.name}</li>
                            <li><b>ABHA number </b>: {detail.healthId}</li>
                          {detail.abha_address!='' ? <li><b>ABHA address</b> : {detail.abha_address}</li>: '' }  
                        </ul>
                     </div>
                     </div>
                    <div className="forgot-sec">
                    <hr></hr>
                    {/* <div className="mt-2"><a className="end-text" href="">Forgot your ABHA number ?</a></div>
                    <div className="mt-2"><a className="end-text" href="">Track Your Enrolment Number</a></div>                     */}
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard_layout>
    </div>
  );
};
