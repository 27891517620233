
import React from "react";

import { useSelector } from "react-redux";
import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";
import { Settings } from "@material-ui/icons";

//   window.location.reload();

// const datastring = Localdata().data;
const agdata=localStorage.getItem('agoraaddress');
console.log(agdata,'datastringdatastring')

const dataobj= agdata && JSON.parse(agdata);
// console.log(dataobj,'token1tossssssssssssssssssssssssssssssssssssssssssssssssken1');

// console.log(dataobj?.channel_name,'channel_namechannel_namechannel_name');
// console.log(dataobj?.token,'tokentokentokentokentoken');


// const token1=dataobj!=undefined ? dataobj.appid.token : '';
// const channel1=dataobj!=undefined ? dataobj.appid.channel_name : '';

                     
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
// console.log(token1,'token1token1');

// console.log(channel1,'channel1channel1channel1channel1');
// console.log(dataobj.appid.token,'token');



const appId = "58f05d2722fc430181051bddbe210492";
const token = "007eJxTYFhf4yWUFHf4QxtrxIRrlkf7HJL1N82Rka65d5xzzzU2uZMKDKYWaQamKUbmRkZpySbGBoYWhgamhkkpKUmpRoYGJpZGW1WuJjcEMjJY7NrCyMgAgSA+K0NuakqlEQMDAA/aHnI=";
// export const config = { mode: "rtc", codec: "vp8", appId: appId, token: token};
export const config = { mode: "rtc", codec: "vp8", appId: appId, token: dataobj?.token};

export const useClient = createClient(config);
export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
// export const channelName = 'medy2';
export const channelName = dataobj?.channel_name;

export default Settings;