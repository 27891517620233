// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import { persistStore } from "redux-persist";
// import store from './store/store';
// import { PersistGate } from 'redux-persist/integration/react';
// import { Provider } from 'react-redux';
// import HttpsRedirect from 'react-https-redirect';
// import { BrowserRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );

// let persistor = persistStore(store);

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <HttpsRedirect>
//           <App />
//         </HttpsRedirect>
//       </PersistGate>
     
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// reportWebVitals();
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// // reportWebVitals();



import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistStore } from "redux-persist";
import store from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';
// import { BrowserRouter } from 'react-router-dom';

  


let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    
      <PersistGate loading={null} persistor={persistor}>
        {/*<HttpsRedirect> */}

          <App />
          
       {/* </HttpsRedirect> */}
      </PersistGate>
    
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();