import React,{useState} from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import { useLocation } from "react-router";
import  axios  from 'axios';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import { Button } from 'react-bootstrap';

export const AbhaValidate = () => {
  const navigate = useNavigate();
  const [adharprocess, setadharprocess] = useState(false);


  const { state } = useLocation("/abha-login");

    const { healthId } = state;

    const { token } = state;
const[type,settype]=useState(1)

  const handlesubmit=async()=>{



    const payloadfinal = {
      authMethod: type==1 ? 'AADHAAR_OTP' : 'MOBILE_OTP',
      healthid: healthId,
    }
    try {
      setadharprocess(true)
      const adharapifinal = await axios.post("https://www.lms.extreme.org.in/api/authmethod", payloadfinal, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })

      console.log(adharapifinal, 'innerapifinal');
console.log(adharapifinal.data.status,'adharapifinal.data.status')
      if (adharapifinal.data.status == 1) {


        navigate('/abhaotp',{state:{txnid:adharapifinal.data.data,token:token,type:type,authMethod: type==1 ? 'AADHAAR_OTP' : 'MOBILE_OTP'}})

      } else {
        toast.error(adharapifinal.data.message);
        setadharprocess(false);
      }
    } catch (error) {
      console.log(error)
      setadharprocess(false);

    }
  }

  console.log(type,'tytptee');
  return (
    <div>
      <Dashboard_layout>
        <ToastContainer />
        <div className="content-top-padding">
          <div className="card shadow mb-4">
            <div>
              <div className="abha-number-section">
                <div className="">
                  <div className="abha-inner">
                    <div className="abha-heading">
                      <h2>Login to your ABHA number</h2>
                    </div>
                    <hr></hr>
                  
                    <div className="abha-number">
                      <div className="token-item">
                        <div className="form-group">
                          <label>ABHA Number</label>
                          <div className="form-control-input">
                            <input
                              type="number"
                              placeholder={healthId}
                             readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="login-inner">
                      <h6>Validate Using</h6>
                      <div className="">
                        <div className="choose-payment">
                          <input
                            className="form-check-input  flexRadioDefault1 grey-radio"
                            id="radio-1"
                            checked={type ==1 ? true : false}

                            name="radio1"
                            type="radio"
                            value="1"
                            onClick={()=>settype(1)}
                            
                            
                          />
                          <label
                            className="form-check-label fw-bold"
                            for="radio-1"
                          >
                           OTP on Mobile Number linked with Aadhar
                          </label>
                        </div>
                        <div className="">
                          <div className="choose-payment">
                            <input
                              className="form-check-input  flexRadioDefault1 grey-radio"
                              id="radio-1"
                              name="radio1"
                              type="radio"
                              value="2"
                              onClick={()=>settype(2)}

                            />
                            <label
                              className="form-check-label fw-bold"
                              for="radio-1"
                            >
                              OTP on Mobile Number linked with your ABHA number
                            </label>
                            <img src="" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                     
                      <div className="text-center mt-4">
                      <Button disabled={adharprocess == true ? true : false} onClick={()=>handlesubmit()} className="btn btn-primary ">{adharprocess == true ? 'Processing' : 'Next'}</Button>
                      </div>
                    
                    {/* <hr></hr> */}
                    {/* <div className="forgot-sec">
                    <div className="mt-2"><a className="end-text" href="">Forgot your ABHA number ?</a></div>
                    <div className="mt-2"><a className="end-text" href="">Track Your Enrolment Number</a></div>                    
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard_layout>
    </div>
  );
};
