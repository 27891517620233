import React from "react";
import { useState, useEffect } from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router";

import axiosNew from "../../http/axios/axios_new";
import useAuth from "../../hooks/useAuth";
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";


const Feedback = () => {

  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const { state } = useLocation("/appoint_list");

  const { appointid } = state;

  const { pid } = state;
  const [viewdata, setviewdata] = useState([]);


  const [inputdata, setInputdata] = useState([]);
  const [fromError, setFromError] = useState("");

  const formdata = (event, items) => {
    // const name = event.target.name;
    const value = event.target.value;
    setInputdata((prev) => {
      let newstate = [
        ...prev,
        {

          question_id: items.id,
          appointment_id: appointid,
          patient_id: pid,
          mr_number: "",
          vle_id: user.id,
          ratting: value,
        }]

      return newstate;
    });
  };

  useEffect(() => {
    getData();
  }, []);


  const getData = async () => {


    try {
      const res = await axiosNew.post('/api/vle/feedback/question/list');

      console.log(res.data.data, 'qqiioopp')

      setviewdata(res.data.data);

    } catch (error) {

      console.log(error);

    }

  }

  const feedsubmit = async () => {
    console.log(inputdata, 'finalfeed');

    var select_box = document.getElementById("1").value;
    var select_box1 = document.getElementById("2").value;

    var select_box2 = document.getElementById("3").value;

    var select_box3 = document.getElementById("4").value;


    // inputdata.map((items) => {

    //   var select_boxselect = document.getElementById(items.question_id).value = '0';
    // })

    if (select_box == 0) {
      setFromError("Please select Audio/ video quality");
    } else if (select_box1 == 0) {
      setFromError("Please select Software experience");

    }
    else if (select_box2 == 0) {
      setFromError("Please select Response by doctor");

    }
    else if (select_box3 == 0) {
      setFromError("Please select Response by Medyseva Team");

    } else {

      setFromError('');

      try {
        const res_feed = await axiosNew.post('/api/vle/feedback/store', inputdata, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log(res_feed, 'qqiioopp_feed')

        if (res_feed.data.status == 1) {
          // toast.success('Feedback Submitted !Thanks');

          // inputdata.map((items) => {

          //   var select_box33 = document.getElementById(items.question_id).value = '0';
          // });


          navigate('/appoint_list', { state: { feed: 'feedback' } })

        }

        // setviewdata(res.data.data);

      } catch (error) {

        console.log(error);

      }

    }




  }

  console.log(inputdata, 'iuytr');
  return (
    <Dashboard_layout>
      <ToastContainer />
      <div className="content-top-padding">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary mt-2 pull-left">
              Feedback
            </h6>
          </div>
          <div className="card-body">
            <Form>
              <Row>

                {viewdata && viewdata?.map((items, index) => {

                  return (
                    <Col md="6" sm="6" key={index}>
                      <FormGroup className="mb-3">
                        <label>{items.feedback_question}</label>
                        <InputGroup>
                          <select
                            class="form-select"
                            name="industry"
                            id={items.id}
                            value={inputdata.ratting}

                            onChange={(e) => formdata(e, items)}
                            aria-label="Default select example"
                          >
                            <option disabled selected hidden value="0">Select Feedback Rating</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )
                })}

                {/* <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Patient MR Number</label>
                    <InputGroup>
                      <FormControl
                        type="text"
                        name="password"
                        placeholder="Create password"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Appointment Date</label>
                    <InputGroup>
                      <FormControl
                        type="text"
                        name="date"
                        placeholder="01-10-2022"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Future Communication mode</label>
                    <InputGroup>
                      <select
                        class="form-select"
                        name="industry"
                        aria-label="Default select example"
                      >
                        <option selected>Select mode</option>
                        <option value="1">Whatsapp</option>
                        <option value="3">Mail</option>
                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Patient Feedback</label>
                    <InputGroup>
                      <select
                        class="form-select"
                        name="industry"
                        aria-label="Default select example"
                      >
                        <option selected>Select feedback</option>
                        <option value="1">Whatsapp</option>
                        <option value="3">Mail</option>
                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Cost Feedback</label>
                    <InputGroup>
                      <select
                        class="form-select"
                        name="industry"
                        aria-label="Default select example"
                      >
                        <option selected>Select cost feedback</option>
                        <option value="1">Whatsapp</option>
                        <option value="3">Mail</option>
                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Service Quality*</label>
                    <InputGroup>
                      <select
                        class="form-select"
                        name="industry"
                        aria-label="Default select example"
                      >
                        <option selected>SelectServic Quality*</option>
                        <option value="1">Whatsapp</option>
                        <option value="3">Mail</option>
                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Telemedicine comfort*</label>
                    <InputGroup>
                      <select
                        class="form-select"
                        name="industry"
                        aria-label="Default select example"
                      >
                        <option selected>Select quality</option>
                        <option value="1">Whatsapp</option>
                        <option value="3">Mail</option>
                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>From where you got to know about Medyseva*</label>
                    <InputGroup>
                      <select
                        class="form-select"
                        name="industry"
                        aria-label="Default select example"
                      >
                        <option selected>Select one</option>
                        <option value="1">Whatsapp</option>
                        <option value="3">Mail</option>
                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>All over rating*</label>
                    <InputGroup>
                      <select
                        class="form-select"
                        name="industry"
                        aria-label="Default select example"
                      >
                        <option selected>Select rating</option>
                        <option value="1">Whatsapp</option>
                        <option value="3">Mail</option>
                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Notes*</label>
                    <InputGroup>
                      <FormControl
                        type="text"
                        name="date"
                        placeholder="01-10-2022"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Follow Up Type*</label>
                    <InputGroup>
                      <select
                        class="form-select"
                        name="industry"
                        aria-label="Default select example"
                      >
                        <option selected>Lab investigation</option>
                        <option value="1">Whatsapp</option>
                        <option value="3">Mail</option>
                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Follow Up Type*</label>
                    <InputGroup>
                      <select
                        class="form-select"
                        name="industry"
                        aria-label="Default select example"
                      >
                        <option selected>Lab investigation</option>
                        <option value="1">Whatsapp</option>
                        <option value="3">Mail</option>
                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="6" sm="6">
                  <FormGroup className="mb-3">
                    <label>Follow Up Type*</label>
                    <InputGroup>
                      <FormControl
                        id="datepicker"
                        type="date"
                        className="form-control"
                        name="start"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col> */}

                <div className="feed_error">{fromError}</div>
              </Row>
              <div className="text-center">
                <Button
                  onClick={() => feedsubmit()}
                  type="button"
                  className="btn btn-primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Dashboard_layout>
  );
};

export default Feedback;
