import cryptoJs, { enc } from 'crypto-js';
var CryptoFormater = {
    stringify: function (cipherParams) {
        var j = {ct: cipherParams.ciphertext.toString(cryptoJs.enc.Base64)};
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j);
    },
    parse: function (jsonStr) {
        var j = JSON.parse(jsonStr);
        var cipherParams = cryptoJs.lib.CipherParams.create({ciphertext: cryptoJs.enc.Base64.parse(j.ct)});
        if (j.iv) cipherParams.iv = cryptoJs.enc.Hex.parse(j.iv)
        if (j.s) cipherParams.salt = cryptoJs.enc.Hex.parse(j.s)
        return cipherParams;
    }
}
export default CryptoFormater;