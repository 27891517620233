import React from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import { Button, Col, Container, Form, FormLabel, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";

// import { Navigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import ReactPaginate from "react-paginate";
import axiosNew from "../../http/axios/axios_new";
import helpers from "../../Helper/CryptoHelper";
// import axiosNew from './../../http/axios/axios_new';

const TopupList = () => {
  const { user, isAuthenticated } = useAuth();

  const [showed, setShow] = useState(false);

  const [searchTerm, setsearchTerm] = useState("");

  const [topdetail, settopdetail] = useState(false);

  const [inputdatacount, setinputdatacount] = useState({
    pagecount: 30,

  });
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 20;
  const pageVisited = pageNumber * usersPerPage;
  // const [precription_id, setprecription_id] = useState('');


  const formdatacount = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputdata({ ...inputdatacount, [name]: value });
  };


  // const navigate =useNavigate();
  const navigate = useNavigate();

  const [inputdata, setInputdata] = useState({
    user_id: "",
    amount: "",
    deposit_slip: "",
    utr_no: "",
  });

  const handleTopup = async (data) => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const [photo, setPhoto] = useState({
    loading: false,
    file: null,
    photoUrl: null,
  });

  const { file, photoUrl, loading } = photo;

  const handleformdata = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputdata({ ...inputdata, [name]: value });
  };

  const photoUploadHandler = (event, setState) => {
    const { files } = event.target;

    const reader = new window.FileReader();
    reader.readAsArrayBuffer(files[0]);

    reader.onloadend = () => {
      // setContentImage(reader.result);
    };

    if (files[0]) {
      if (files[0].type.includes("image")) {
        const filename = files[0].name;
        const fileExtension = filename.substr(filename.lastIndexOf(".") + 1);

        if (
          fileExtension.toLowerCase() === "png" ||
          fileExtension.toLowerCase() === "jpg" ||
          fileExtension.toLowerCase() === "gif" ||
          fileExtension.toLowerCase() === "jpeg" ||
          fileExtension.toLowerCase() === "webp"
        ) {
          setState({
            ...photo,
            file: event.currentTarget.files[0],
            photoUrl: URL.createObjectURL(files[0]),
          });
        }
      }
    }
  };

  const HandleSubmit = async () => {
    const formData = new FormData();
    try {
      console.log("datasub");
      console.log(inputdata.amount, "amount");
      console.log(user, "user");
      console.log(inputdata.utr_no, "utr_no");
      console.log(file, "file");

      formData.append("user_id", user.id);
      formData.append("amount", inputdata.amount);
      formData.append("deposit_slip", file);
      formData.append("utr_no", inputdata.utr_no);

      console.log(formData, "formdata");
      let result = await axiosNew.post(
        "/api/vle/topup_request/add",
        // "https://api.medyseva.com/api/vle/topup_request/add",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      if (result.data.status == 1) {
        setShow(false);
        toast.success(" Topup Request Sent Successfully");
        setTimeout(getData(), 3000);
      } else {
        if (inputdata.amount || inputdata.utr_no == '') {

          toast.error("Please Enter Valid Data");
        } else {
          toast.error(" Topup Request Failed");
        }

      }
      // console.log(result, 'yesss');
    } catch (error) {
      console.log(error, "ye error");
    }
  };

  const getData = async () => {
    console.log('vegan');
    let inputdataset = {
      user_id: user.id,
      offset: 0,
      limit: inputdatacount.pagecount,
    };

    let res = await axiosNew.post( "/api/vle/topup_request",
      // "https://api.medyseva.com/api/vle/topup_request",

      helpers.encrypt(JSON.stringify(inputdataset))
    );
    //console.log(JSON.parse(res), "sir data");
    res.data = helpers.decrypt(JSON.stringify(res.data));
    // res=await JSON.parse(res.data.patients)
    console.log("top3434", res.data.original.data);
    // if (res.data.status == "1") {
    settopdetail(res.data.original.data.slice(0, 1000));

    //   // setpatients_data();
    // }
  };

  useEffect(() => {
    getData();
  }, []);
  // }, [inputdatacount.pagecount]);

  const pageCount = Math.ceil(topdetail.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo(0, 0);

  }

  return (
    <Dashboard_layout>
      <ToastContainer />
      <div className="content-top-padding">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary mt-2 pull-left">
              Topup Requests
            </h6>
            <a
              onClick={(e) => handleTopup()}
              className="btn btn-primary pull-right"
            >
              Topup Requests
            </a>
          </div>
          <div className="card-body">
            {/* <div className="row">
              <div class="col-sm-6">
                <div className="drop-flex">
                  <p>Show </p>
                  <select onChange={formdatacount}
                    name="pagecount"
                    value={inputdatacount.pagecount}
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                  </select>
                  <p>entries</p>
                </div>
              </div>
              <div class="col-sm-6">
                <div className="drop-flex">
                  <label>Search:</label>
                  <input
                    type="search"
                    class="form-control input-sm"
                    placeholder="Search by amount..."
                    aria-controls="appoinment_table"
                  ></input>
                </div>
              </div>
            </div> */}
            <div className="table-responsive">
              <table
                className="table table-bordered vle-table"
                id="dataTable"
                width="100%"
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Amount</th>
                    <th>UTR No.</th>
                    <th>Date</th>
                    <th>Deposit Slip</th>
                    <th>Status</th>
                    <th>Wallet Amount</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {topdetail &&
                    [...topdetail]?.slice(pageVisited, pageVisited + usersPerPage)?.map((items, index) => {
                      return (
                        <tr>
                          <td>{pageVisited + (index + 1)}</td>
                          <td>{items.amount}</td>
                          <td>{items.utr_no}</td>
                          <td>{moment(items.created_at).format("YYYY-MM-DD")}</td>
                          <td>
                            <a
                              href={items.deposit_slip}
                              class="btn btn-light-secondary btn-sm"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="View"
                              target="_blank"
                            >
                              View
                            </a>
                          </td>
                          <td>{items.status}</td>
                          <td>{items.amount}</td>
                          {/* <td>
                            <a
                              class="btn btn-light-secondary btn-sm"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="View"
                            >
                              <i class="far fa-eye"></i>
                            </a>
                          </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="appointment-modal"
        show={showed}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Header className="head-modal1" closeButton>
          <Modal.Title>Topup Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
         
            <div className="">
            <img
                  src="assets\img\QR.jpeg"
                   height="300px" 
                   style={{float:'left'}}
                />
            </div>
          
              <Row>
                <div className=" mb-4">
                  <Form.Label className="bold-head">Amount*</Form.Label>
                  <Form.Control
                    placeholder="Enter"
                    onChange={handleformdata}
                    value={inputdata.amount}
                    name="amount"
                  />
                </div>
              </Row>
              <Row>
                <div className="mb-4">
                  <Form.Label className="bold-head">UTR No.</Form.Label>
                  <Form.Control
                    placeholder="Enter"
                    onChange={handleformdata}
                    value={inputdata.utr_no}
                    name="utr_no"
                  />
                </div>
              </Row>
              <Row>
                <div className="mb-4">
                  <Form.Label className="bold-head">Deposit Slip*</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Enter"
                    onChange={(e) => photoUploadHandler(e, setPhoto)}
                    onDragOver="drag()"
                    onDrop="drop()"
                    id="uploadFile"
                  />
                </div>
              </Row>
              <Container>
                <div
                  className="justify-content-center"
                  style={{ display: "flex" }}
                >
                  <Button
                    className="btn btn-primary margin-btn"
                    onClick={() => setShow(false)}
                  >
                    Close
                  </Button>
                  <Button
                    id="entersubmit"
                    className="btn btn-primary margin-btn"
                    type="button"
                    onClick={() => HandleSubmit()}
                       >
                    Submit
                  </Button>
                </div>
              </Container>
       
         
          </Form>
        </Modal.Body>
      </Modal>
    </Dashboard_layout>
  );
};

export default TopupList;
