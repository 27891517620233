import cryptoJs, { enc } from 'crypto-js';
import CryptoFormater from './CryptoFormater';

const helpers = {
    encrypt: function(data){
        var key = "e5jOGpNF5alP4yr&3PBu4UY8JdUx69$i";
        var encrypted = cryptoJs.AES.encrypt(data, key, {format: CryptoFormater})
        return encrypted.toString();
    },
    decrypt: function(data){
        var key = "e5jOGpNF5alP4yr&3PBu4UY8JdUx69$i";       
        var decrypted = JSON.parse(cryptoJs.AES.decrypt(data, key,{format: CryptoFormater}).toString(cryptoJs.enc.Utf8));
        return decrypted;
    },
    
    
}
export default helpers;