import React from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from "react-router";
import { Button} from "react-bootstrap";

export const AbhaLogin = () => {

  const navigate = useNavigate();
  const [checked, setchecked] = useState('aadhar');



  const [inputadhardata, setinputadhardata] = useState({
    healthId: '',
    year: ''
  });

  const [formmobile, setformmobile] = useState('');
  const [adharprocess, setadharprocess] = useState(false);



  const formdata = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputadhardata({ ...inputadhardata, [name]: value })
  }
  const formmobiledata = (event) => {
    setformmobile(event.target.value);
  }


  const [usertoken, setusertoken] = useState();
  console.log(checked, 'happy')

  useEffect(() => {
    checked == 'mobile' ? document.getElementById('mobile_section').style.display = "block" : document.getElementById('mobile_section').style.display = "none";
    checked == 'aadhar' ? document.getElementById('abhar_section').style.display = "block" : document.getElementById('abhar_section').style.display = "none";
  }, [checked])



console.log(checked,'33333333333333')
  const handleSubmit = async () => {
    if(inputadhardata.healthId==""){
toast.warn('Please enter ABHA Number');
    }
    
    // else if(inputadhardata.year==""){
    //   toast.warn('Please enter Year Of Birth');

    // }
    else{
      setadharprocess(true)

      try {
        const tokenapi = await axios.get('https://www.lms.extreme.org.in/api/token');
    
        if (tokenapi.data.status == 1) {
          setusertoken(tokenapi.data.data);
    
          const payload = {
            healthId: inputadhardata.healthId,
            // year: inputadhardata.year
          }
    
          try {
            const adharapi = await axios.post("https://www.lms.extreme.org.in/api/search_healthid", payload, {
              headers: {
                authorization: `Bearer ${tokenapi.data.data}`,
              },
            })
       
          
            if (adharapi.data.status == 1) {
         
                      navigate('/abha-validate',{state:{healthId:inputadhardata.healthId,token:tokenapi.data.data}});
            } else {
              setadharprocess(false)
    
              toast.error(adharapi.data.message);
            }
    
    
          } catch (error) {
            setadharprocess(false)
    
            console.log(error)
          }
    
    
    
    
        } else {
          toast.error(tokenapi.data.message);
        }
    
      } catch (error) {
     

        console.log(error)
      }
    
    }


  }

const handlemobilesubmit=async()=>{


  if(formmobile==""){
toast.warn("Enter Your Mobile Number")
  }else{
    try {
      setadharprocess(true)

      const tokenapi = await axios.get('https://www.lms.extreme.org.in/api/token');
  
      if (tokenapi.data.status == 1) {
        setusertoken(tokenapi.data.data);
  
        const payload = {
          mobile: formmobile
        }
  
        try {
          const adharapi = await axios.post("https://www.lms.extreme.org.in/api/loginmobile", payload, {
            headers: {
              authorization: `Bearer ${tokenapi.data.data}`,
            },
          })
  
  
          if (adharapi.data.status == 1) {
                    navigate('/abha-mobileotp',{state:{token:tokenapi.data.data,txnid:adharapi.data.data}});
          } else {
            setadharprocess(false)
  
            toast.error(adharapi.data.message);
          }
  
  
        } catch (error) {
          setadharprocess(false)
          console.log(error)
        }
  
  
  
  
      } else {
        toast.error(tokenapi.data.message);
      }
  
    } catch (error) {
  
      console.log(error)
    }
  }
 

}

  return (
    <div>
      <Dashboard_layout>
        <ToastContainer />
        <div className="content-top-padding">
          <div className="card shadow mb-4">
            <div>
              <div className="abha-number-section">
                <div className="">
                  <div className="abha-inner">
                    <div className="abha-heading">
                      <h2>Login to your ABHA number</h2>
                    </div>
                    <hr></hr>
                    <div className="login-inner">
                      <h5>Login Using</h5>
                      <div className="">
                        <div className="choose-payment">
                          <input
                            className="form-check-input  flexRadioDefault1 grey-radio"
                            id="radio-1"
                            name="radio1"
                            type="radio"
                            value="mobile"
                            onClick={(e) => setchecked(e.target.value)}
                          />
                          <label
                            className="form-check-label fw-bold"
                            for="radio-1"
                          >
                            Mobile
                          </label>
                        </div>
                        <div className="">
                          <div className="choose-payment">
                            <input
                              className="form-check-input  flexRadioDefault1 grey-radio"
                              id="radio-1"
                              name="radio1"
                              type="radio"
                              value="aadhar"
                              checked={checked == 'aadhar' ? true : false}
                              onClick={(e) => setchecked(e.target.value)}
                            />
                            <label
                              className="form-check-label fw-bold"
                              for="radio-1"
                            >
                              ABHA number
                            </label>
                            <img src="" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="abha-number" id="abhar_section">
                      <hr></hr>

                      <div className="token-item">
                        <div className="form-group">
                          <label>ABHA Number or ABHA Address</label>
                          <div className="form-control-input">
                            <input
                              type="text"
                              value={inputadhardata.healthId}
                              placeholder="ABHA number"
                              onChange={formdata}
                              name='healthId'

                            />
                          </div>
                        </div>
                      </div>
                      <div className="token-item" style={{display:'none'}}>
                        <div className="form-group">
                          <label>Year Of Birth</label>
                          <div className="form-control-input">
                            <select
                              className='form--dob-year'
                              name='year'
                              onChange={formdata}
                              value={inputadhardata.year}

                            >
                              <option value='0'>Year</option>
                              <option value='2019'>2019</option>
                              <option value='2018'>2018</option>
                              <option value='2017'>2017</option>
                              <option value='2016'>2016</option>
                              <option value='2015'>2015</option>
                              <option value='2014'>2014</option>
                              <option value='2013'>2013</option>
                              <option value='2012'>2012</option>
                              <option value='2011'>2011</option>
                              <option value='2010'>2010</option>
                              <option value='2009'>2009</option>
                              <option value='2008'>2008</option>
                              <option value='2007'>2007</option>
                              <option value='2006'>2006</option>
                              <option value='2005'>2005</option>
                              <option value='2004'>2004</option>
                              <option value='2003'>2003</option>
                              <option value='2002'>2002</option>
                              <option value='2001'>2001</option>
                              <option value='2000'>2000</option>
                              <option value='1999'>1999</option>
                              <option value='1998'>1998</option>
                              <option value='1997'>1997</option>
                              <option value='1996'>1996</option>
                              <option value='1995'>1995</option>
                              <option value='1994'>1994</option>
                              <option value='1993'>1993</option>
                              <option value='1992'>1992</option>
                              <option value='1991'>1991</option>
                              <option value='1990'>1990</option>
                              <option value='1989'>1989</option>
                              <option value='1988'>1988</option>
                              <option value='1987'>1987</option>
                              <option value='1986'>1986</option>
                              <option value='1985'>1985</option>
                              <option value='1984'>1984</option>
                              <option value='1983'>1983</option>
                              <option value='1982'>1982</option>
                              <option value='1981'>1981</option>
                              <option value='1980'>1980</option>
                              <option value='1979'>1979</option>
                              <option value='1978'>1978</option>
                              <option value='1977'>1977</option>
                              <option value='1976'>1976</option>
                              <option value='1975'>1975</option>
                              <option value='1974'>1974</option>
                              <option value='1973'>1973</option>
                              <option value='1972'>1972</option>
                              <option value='1971'>1971</option>
                              <option value='1970'>1970</option>
                              <option value='1969'>1969</option>
                              <option value='1968'>1968</option>
                              <option value='1967'>1967</option>
                              <option value='1966'>1966</option>
                              <option value='1965'>1965</option>
                              <option value='1964'>1964</option>
                              <option value='1963'>1963</option>
                              <option value='1962'>1962</option>
                              <option value='1961'>1961</option>
                              <option value='1960'>1960</option>
                              <option value='1959'>1959</option>
                              <option value='1958'>1958</option>
                              <option value='1957'>1957</option>
                              <option value='1956'>1956</option>
                              <option value='1955'>1955</option>
                              <option value='1954'>1954</option>
                              <option value='1953'>1953</option>
                              <option value='1952'>1952</option>
                              <option value='1951'>1951</option>
                              <option value='1950'>1950</option>
                              <option value='1949'>1949</option>
                              <option value='1948'>1948</option>
                              <option value='1947'>1947</option>
                              <option value='1946'>1946</option>
                              <option value='1945'>1945</option>
                              <option value='1944'>1944</option>
                              <option value='1943'>1943</option>
                              <option value='1942'>1942</option>
                              <option value='1941'>1941</option>
                              <option value='1940'>1940</option>
                              <option value='1939'>1939</option>
                              <option value='1938'>1938</option>
                              <option value='1937'>1937</option>
                              <option value='1936'>1936</option>
                              <option value='1935'>1935</option>
                              <option value='1934'>1934</option>
                              <option value='1933'>1933</option>
                              <option value='1932'>1932</option>
                              <option value='1931'>1931</option>
                              <option value='1930'>1930</option>
                              <option value='1929'>1929</option>
                              <option value='1928'>1928</option>
                              <option value='1927'>1927</option>
                              <option value='1926'>1926</option>
                              <option value='1925'>1925</option>
                              <option value='1924'>1924</option>
                              <option value='1923'>1923</option>
                              <option value='1922'>1922</option>
                              <option value='1921'>1921</option>
                              <option value='1920'>1920</option>
                              <option value='1919'>1919</option>
                              <option value='1918'>1918</option>
                              <option value='1917'>1917</option>
                              <option value='1916'>1916</option>
                              <option value='1915'>1915</option>
                              <option value='1914'>1914</option>
                              <option value='1913'>1913</option>
                              <option value='1912'>1912</option>
                              <option value='1911'>1911</option>
                              <option value='1910'>1910</option>
                              <option value='1909'>1909</option>
                              <option value='1908'>1908</option>
                              <option value='1907'>1907</option>
                              <option value='1906'>1906</option>
                              <option value='1905'>1905</option>
                              <option value='1904'>1904</option>
                              <option value='1903'>1903</option>
                              <option value='1902'>1902</option>
                              <option value='1901'>1901</option>
                              <option value='1900'>1900</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4" onClick={() => handleSubmit()}>
                        <Button disabled={adharprocess == true ? true : false} className="btn btn-primary ">{adharprocess == true ? 'Processing' : 'Continue'}</Button>
                      </div>
                    </div>
                    <div className="abha-number1" id="mobile_section">
                      <div className="token-item">
                        <div className="form-group">
                          <label>Enter Your Mobile Number</label>
                          <div className="form-control-input">
                            <input
                              type="number"
                              name="mobile"
                              placeholder="Mobile Number"
                              value={formmobile}
                              onChange={formmobiledata}

                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <Button disabled={adharprocess == true ? true : false} onClick={()=>handlemobilesubmit()} className="btn btn-primary ">{adharprocess == true ? 'Processing' : 'Continue'}</Button>
                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard_layout>
    </div>
  );
};
