import React from 'react'
import Dashboard_layout from "../Layout/Dashboard_layout";
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import { Button } from "react-bootstrap";
import axios from 'axios';
import { useEffect, useState } from 'react';

const Abhaadharpng = () => {
    const { state } = useLocation("/abhaotp");
    const [imageUrl, setImageUrl] = useState(null);


    const { token } = state;
    const { xtoken } = state;


    useEffect(async () => {
        try {
            const apiresult = await axios.get('https://healthidsbx.abdm.gov.in/api/v1/account/getPngCard', {
                headers: {
                    authorization: `Bearer ${token}`,
                    'X-Token': `Bearer ${xtoken}`
                },
                responseType: 'blob'

            })


            const scrurl = URL.createObjectURL(apiresult.data);
            document.getElementById('usercard').src = scrurl;
            document.getElementById('anchorbase').href = scrurl;

            setImageUrl(URL.createObjectURL(apiresult.data));
            console.log(apiresult, 'apiresultapiresultapiresult');
        } catch (error) {
            console.log(error)

        }
    }, []);

    return (
        <Dashboard_layout>
            <div>


                <div className="content-top-padding">
                    <div className="card shadow mb-4">
                        <div>
                            <div className="abha-number-section">
                                <div className="">
                                    <div className="abha-inner">

                                        <div className="abha-inner-info step-6-section">

                                            <div className="profile-detail-info-block">


                                                <div className=''>
                                                    <div className="card-header header-body py-3">
                                                        <div className="prescription_headers1">
                                                            <div className="row">
                                                                <div className="col-md-12 text-right printhl">


                                                                    <img
                                                                        src=""
                                                                        alt=""
                                                                        className="img-fluid chamber-img1"
                                                                        id="usercard"
                                                                    />

                                                                    {/* <h4 style={{ textAlign: 'center', margin: 'auto', padding: '6%' }} id="loading_line" >Loading...</h4> */}

                                                                </div>


                                                                <Button variant="primary" style={{ width: '30%', margin: 'auto' }}> <a style={{ color: 'white' }} id="anchorbase" download href="" target="_blank">download</a></Button>



                                                                {/* <button onClick={() => blob()}>click me</button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard_layout>
    )
}

export default Abhaadharpng