
import React, { useState, useEffect } from 'react';
import { Button } from "@material-ui/core";
import VideoCall from "./VideoCall";

import { useDispatch } from "react-redux";
import { agoraids } from '../../../reducer/authReducers';

function Demo() {

      // window.location.reload();

    const dispatch = useDispatch();
    const [inCall, setInCall] = useState(false);
    const agoralink=()=>{
        setInCall(true);
    }
  return (
    <div className="App" style={{ height: "100%" }}>
    {inCall ? (
      <VideoCall setInCall={setInCall} />
    ) : (
      <>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={() => agoralink()}
      >
        Join Call
      </Button> */}
      
      <div className="content-top-padding blue-box">
      <div className='col-md-8'>
            <section className="card-outer-box">
              <div className="inner-content">                
                <div className='row align-items-center justify-content-center'>
                    <div className='col-md-4'>
                        <div className=''>
                            <img src="assets\img\logo\logo.png" alt='product' className='img-fluid'></img>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='text-center sample-product'>
                            <h3>Medyseva Meeting online</h3>
                            <h4>Connect anytime</h4>
                            <p>Join a seamless video call experience with our user-friendly platform. Connect with your team or clients from anywhere, at any time, with just a few clicks. Get started now.</p>
                            <div className=''>
                              <a className='cart-btn'  style={{cursor:'pointer'}} onClick={() => agoralink()}>JOIN CALL</a>
                            </div>
                        </div>
                    </div>
                </div>               
              </div>
            </section>
            </div>
            </div>













      </>
    )}
  </div>
  );
}

export default Demo;
