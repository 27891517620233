import React from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import { Button, Col, Container, Form, FormLabel, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";

// import { Navigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import ReactPaginate from "react-paginate";
import axiosNew from "../../http/axios/axios_new";
// import axiosNew from './../../http/axios/axios_new';

const Abhalist = () => {
  const { user, isAuthenticated } = useAuth();
  const [data, setdata] = useState();

  useEffect(async () => {

    const result = await axios.post('https://api-medyseva-com.tejpage.com/api/vle/abha_users', {
      vle_id: user.id
    });

    setdata(result.data.data);
    console.log(result.data.data[0].aadharcard_number,'resultresult');

  }, [])


  return (
    <Dashboard_layout>
      <ToastContainer />
      <div className="content-top-padding">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary mt-2 pull-left">
              Abha card list
            </h6>

            <Link to="/abha-login">
              <a
                className="btn btn-primary pull-right"
              >
               Abha Login
              </a>
            </Link>
           
            <Link to="/abha">
              <a
              style={{marginRight:5}}
                className="btn btn-primary pull-right"
              >
                Create abha card
              </a>
            </Link>

           
          </div>
          <div className="card-body">

            <div className="table-responsive">
              <table
                className="table table-bordered vle-table"
                id="dataTable"
                width="100%"
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Abha Number</th>
                    <th>Adhar card number</th>
                    <th>Mobile number</th>


                  </tr>
                </thead>
                <tbody>
                  {data && data?.map((item, index) => {
                    return (
                      <tr>
                        <td>{item.username}</td>
                        <td>{item.health_id}</td>
                        <td>{item.aadharcard_number}</td>
                        <td>{item.mobile}</td>

                      </tr>
                    )
                  })}


                </tbody>
              </table>
              {/* <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              /> */}
            </div>
          </div>
        </div>
      </div>


    </Dashboard_layout>
  );
};

export default Abhalist;
