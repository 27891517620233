import React, { useState, useEffect } from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import axiosMain from "../../http/axios/axios_main";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import axios from "axios";
import ReactPaginate from "react-paginate";
import axiosNew from './../../http/axios/axios_new';
import helpers from "../../Helper/CryptoHelper";

const Wallet_details = () => {
  const { user, isAuthenticated } = useAuth();
  const [wallet_tran, setwallet_tran] = useState({
    isLoading: false,
    data: [],
    error: ""

  });
  const [balance_patient, setbalance_patient] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 15;
  const pageVisited = pageNumber * usersPerPage;
  // const [precription_id, setprecription_id] = useState('');
  const [inputdata, setInputdata] = useState({
    pagecount: 30,
  });
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setwallet_tran((prev) => {

        return {
          ...prev,
          isLoading: true,
        }
      })
      let inputdatawallet = {
        user_id: user.id,
        limit: 500,
        offset: 0,
      };
      // console.log("zcsvfdv");
      // let res = await axios.post("https://api.medyseva.com/api/vle-wallet-history", inputdatawallet);

      let res = await axiosNew.post("/api/vle-wallet-history", helpers.encrypt(JSON.stringify(inputdatawallet)));

      res.data = helpers.decrypt(JSON.stringify(res.data));
      console.log("wallet hus", res);
      if (res.data.original.status == "1") {
        // setwallet_tran(res.data.data.history);
        setwallet_tran({
          isLoading: false,
          data: res.data.original.data.history.slice(0, 500),
          error: ""
        })
        setbalance_patient(res.data.original.data.wallet.amount);
      }
    } catch (error) {
      console.log(error);
      setwallet_tran({
        isLoading: false,
        data: [],
        error: error.data,
      });
    }
  };
  console.log("patientsaaaa", wallet_tran);

  const pageCount = Math.ceil(wallet_tran.data.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo(0, 0);

  }
  
let loadbalance='Balance Loading'
  return (
    <Dashboard_layout>
      <div>
        <div className="content-top-padding">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary mt-2">Wallet</h6>
            </div>
            <div className="card-body">
              <div>
                <div className="wallet-balance">
                  <h2>
                    Balance : Rs. <b>{wallet_tran.isLoading  ? <h5 style={{fontSize:13,color:'red'}}>{loadbalance}</h5> :  balance_patient ? balance_patient : ''}</b>
                  </h2>
                </div>
                {/* <div>
                  <a className="btn btn-primary">View History</a>
                </div> */}
              </div>
              <div className="table-responsive">
                <table
                  className="table table-bordered vle-table"
                  id="dataTable"
                  width="100%"
                  cellSpacing={0}
                >
                  <thead>
                    <tr>
                      <th>Transaction Id</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Remark</th>
                      <th>Amount</th>
                      <th>Type</th>
                      <th>Wallet Amount</th>
                      <th>Date</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {wallet_tran.isLoading && (
                      <tr>
                        <td
                          style={{ textAlign: "center", color: "red" }}
                          colspan="8"
                        >
                          Data loading
                        </td>
                      </tr>
                    )}
                    {!wallet_tran?.isLoading &&
                      wallet_tran.data?.slice(pageVisited, pageVisited + usersPerPage).map((items, key) => {
                        return (
                          <tr>
                            <td>{items.trx_id != null ? items.trx_id : ''}</td>
                            <td>{items.from_wallet_name != null ? items.from_wallet_name : ''}</td>
                            <td>{items.from_wallet_name != null ? items.to_wallet_name : ''}</td>
                            <td>

                            {items.category}



                              {/* {items.category=='tds' ?  items.category ? items.category.toUpperCase() : items.category[0].toUpperCase() + items.category.substring(1) : ''} */}
                            
                              <br />
                              <small className="text-black">
                              Patient :  <b>{items.patient != null ? items.patient.name.substring(0, 10) ? items.patient.name.substring(0, 20) : '' : ''}</b>
                              </small>
                            </td>
                            <td>{items.amount}</td>
                            <td>{items.from_wallet == user.wallet_id ? 'Debit' : 'Credit'}</td>
                            <td>{items.to_wallet === user.wallet_id ? items.receiver_amount : items.current_amount}</td>
                            <td>{moment(items.created_at).format("YYYY-MM-DD")}</td>
                            {/* <td><a className="btn btn-primary">View</a></td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard_layout>
  );
};

export default Wallet_details;
