import React, { useState } from "react";
import Footer from "../Layout/UI/Footer";
import Header from "../Layout/UI/Header";
import Sidebar from "../Layout/UI/Sidebar";

import "react-toastify/dist/ReactToastify.css";
export default function HomeLayout(props) {
  const [toggleNav, setToogleNav] = useState(false);
  return (
    <>
 
      <div className={`g-sidenav-show ${toggleNav ? "g-sidenav-pinned" : " "} `}>
        <Sidebar setToogleNav={setToogleNav} />

        <main className="main-content position-relative max-height-vh-100 h-100  border-radius-lg ">
          <div className="custom1">
            <Header setToogleNav={setToogleNav} />
            <div className="container-fluid py-4">{props.children}</div>
          </div>
        </main>
      </div>

    </>
  );
}
