import React from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import { Button, Toast, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useState } from "react";
// import axios from "axios";
import { useEffect } from "react";

import axiosMain from "../../http/axios/axios_main";
import axios from "axios"; 
import { toast, ToastContainer } from "react-toastify";
import useAuth from "../../hooks/useAuth";

import { useNavigate } from "react-router-dom";
import DatePicker from 'react-date-picker';
import axiosNew from './../../http/axios/axios_new'; 
import helpers from "../../Helper/CryptoHelper";
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction";

const Appointment_create = () => {

  const navigate = useNavigate();

  const { user, isAuthenticated } = useAuth();
  const [patientdate, setpatientdate] = useState('');
  const [patientType, setPatientType] = useState(0);
  const [activeCheck, setActiveCheck] = useState(true);
  const [consultation_type1, setconsultation_type1] = useState(2);
  const [departments,setDepartments] = useState([]);
  const [doctors,setDoctors] = useState([]);
  const [department,setDepartment] = useState("");
  const [doctor,setDoctor] = useState("");
  const [consult_cheked, setconsult_cheked] = useState(true);
  const [genderData, setGenderData] = useState("");
  const [dateOfAp, setDateOfAp] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [viewappoint, setviewappoint] = useState("");
  const [urlImageold, setUrlImageold] = useState("");
  const [events,setEvents] = useState([]);
  const [upload_img, setupload_img] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [slotTime,setSlotTime] = useState([]);
  const [appointmentSlot,setAppointmentSlot] =useState("")
  const [country,setCountry] = useState([]);
  const [state,setStates] = useState([]);
  const [city,setCity] = useState([]);
  const [selectedCountry,setSelectedCountry]=useState("");
  const [selectedState,setSelectedState]=useState("");
  const [selectedCity,setSelectedCity]=useState("");
  const [salutation,setSalutation]=useState("");
  useEffect(() => {
    if (patientType === "Old Patient" || activeCheck == true) {
      setActiveCheck(true);
      document.getElementById("new_patient").style.display = "none";
      document.getElementById("old_patient").style.display = "block";
      // document.getElementById("old_patient").checked = false;

    }
    if (patientType === "New Patient") {
      document.getElementById("new_patient").style.display = "block";
      document.getElementById("new1").checked = true;
      document.getElementById("old_patient").style.display = "none";
      setActiveCheck(false);
    }
    getDepartment();
    
    
  }, [patientType]);
  let user_id = user.id;

  function setAppointmentDateAndTime(start,end){
    console.log(start)
    setAppointmentSlot([start,end])
    setDateOfAp(formatDate(start))
    var startTime = formatTime(start);
    var endTime = formatTime(end);
    setSlotTime(startTime+' - '+endTime)
    handleClose()
  }

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  function formatTime(date){
    var d = new Date(date);
    var hour = d.getHours();
    var min = String(d.getMinutes()).padStart(2, '0');
    return [hour,min].join(':');
  }
  
  
  const [formData, setFormData] = useState({
    file: "",
    photoUrl: "",
  });


  const [formDataold, setFormDataold] = useState({
    file: "",
    photoUrl: "",
  });
  const [formDatanew, setFormDatanew] = useState({
    file: "",
    photoUrl: "",
  });

  // const { fileold, photoUrlold } = formDataold;


  const { file, photoUrl } = formData;
  let newdata = new FormData();

  const [patientList, setPatientList] = useState("");

  const [fromError, setFromError] = useState("");

  const photoUploadHandler = (event, setState) => {
    const { files } = event.target;
    if (files[0]) {
      if (files[0].type.includes("image") || files[0].type.includes("pdf")) {
        const filename = files[0].name;
        const fileExtension = filename.substr(filename.lastIndexOf(".") + 1);
        if (
          fileExtension.toLowerCase() === "png" ||
          fileExtension.toLowerCase() === "pdf" ||
          fileExtension.toLowerCase() === "jpg" ||
          fileExtension.toLowerCase() === "gif" ||
          fileExtension.toLowerCase() === "jpeg"
        ) {
          setState({
            ...formData,
            file: event.currentTarget.files[0],
            photoUrl: URL.createObjectURL(files[0]),
          });
        }
      }
    }
  };

  const getList = async () => {
    // let res = await axios.post("https://api.medyseva.com/api/vle-patients", {

    let res = await axiosNew.post("/api/vle-patients", helpers.encrypt(JSON.stringify({
      user_id: user.id

    })));
    if (res) {
      res.data = helpers.decrypt(JSON.stringify(res.data));
      setPatientList(res.data.original.patient);
    }
  };
  const getDepartment = async() => {
    let res = await axiosNew.post("/api/doctor/department");
    if (res) {
      setDepartments(res.data.data)
    }
  }

  const getDoctorByDepartment = async() => {
    var department = document.getElementById('department_id').value;
    console.log(department)
    setDepartment(department)
    let res = await axiosNew.post("/api/doctor/department/"+department);
    console.log(res.data)
    if (res) {
      setDoctors(res.data.data)
    }
  }

  const getDoctorsAppointment = async() => {
    var doctor = document.getElementById('doctor').value;
    console.log(department)
    setDoctor(doctor)
    let res = await axiosNew.post("/api/doctor/"+department+"/schedule/"+doctor);
    console.log(res.data)
    if (res) {
      setEvents(res.data.data)
    }
  }

  const checkAvailability = async() => {
    // var doctor = document.getElementById('doctor_id').value;
    // let res = await axiosNew.post("/api/doctor/"+department+"/schedule");
    // console.log(res.data)
    // if (res) {
    //   setDoctors(res.data.data)
    // }

    handleShow()
  }

  const [loadingdata, setloadingdata] = useState({
    isloading: false,

  });

  const [loadingnewdata, setloadingnewdata] = useState({
    isloading: false,

  });
  useEffect(() => {
    getList();
    fetchCountry();
  }, []);


  // Old Patient appoinment code start
  const [inputDataOld, setInputDataOld] = useState({
    temperature: "",
    pulse_rate: "",
    respiratory_rate: "",
    blood_pressure: "",
    patient_report: "",
    height: "",
    weight: "",
    spo2: "",
    fbs: "",
    rbs: "",
    ppbs: "",
    blood_group: "",
    follow_up_patient: "",
    physician: "yes",
    patient_id: "",
  });

  const formdata = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputDataOld({ ...inputDataOld, [name]: value });
  };





  const photoUploadHandler_old = (event, setState) => {
    const { files } = event.target;
    if (files[0]) {
      if (files[0].type.includes("image") || files[0].type.includes("pdf")) {
        const filename = files[0].name;
        const fileExtension = filename.substr(filename.lastIndexOf(".") + 1);
        if (
          fileExtension.toLowerCase() === "png" ||
          fileExtension.toLowerCase() === "pdf" ||
          fileExtension.toLowerCase() === "jpg" ||
          fileExtension.toLowerCase() === "gif" ||
          fileExtension.toLowerCase() === "jpeg"
        ) {
          setState({
            // ...formData,
            file: event.currentTarget.files[0],
            photoUrl: URL.createObjectURL(files[0]),
          });
        }
      }else{
        alert('Invalid File Type, You can only upload PNG, PDF, JPG, GIF and JPEG');
        event.target.value = null;
      }
    }

// console.log(formDataold.file,'azazazazaz');
  }




  const photoUploadHandler_new = (event, setState) => {
    const { files } = event.target;
    if (files[0]) {
      if (files[0].type.includes("image") || files[0].type.includes("pdf")) {
        const filename = files[0].name;
        const fileExtension = filename.substr(filename.lastIndexOf(".") + 1);
        if (
          fileExtension.toLowerCase() === "png" ||
          fileExtension.toLowerCase() === "pdf" ||
          fileExtension.toLowerCase() === "jpg" ||
          fileExtension.toLowerCase() === "gif" ||
          fileExtension.toLowerCase() === "jpeg"
        ) {
          setState({
            // ...formData,
            file: event.currentTarget.files[0],
            photoUrl: URL.createObjectURL(files[0]),
          });
        }
      }
    }

// console.log(formDataold.file,'azazazazaz');
  }

  function handleClick(e){
    console.log('clickSchedule', e);
  }

  console.log(formDataold.file,'ye rhi bsssuuy');

  const payload = {
    vle_id: user.id,
    consultation_type: consultation_type1,
    date: dateOfAp,
    patient_type: "2",
    patient_id: inputDataOld.patient_id,
    preferred_language : inputDataOld.preferred_language,
    time_slot : slotTime,
    doctor_id : doctor,
    department : department,
    country:selectedCountry,
    state:selectedState,
    city:selectedCity,
    // user_id: user,
    patiant_data: {
      temperature: inputDataOld.temperature,
      pulse_rate: inputDataOld.pulse_rate,
      respiratory_rate: inputDataOld.respiratory_rate,
      blood_pressure: inputDataOld.blood_pressure,
      height: inputDataOld.height,

      patient_report: urlImageold,
      weight: inputDataOld.weight,
      spo2: inputDataOld.spo2,
      fbs: inputDataOld.fbs,
      rbs: inputDataOld.rbs,
      ppbs: inputDataOld.ppbs,
      blood_group: inputDataOld.blood_group,
      follow_up_patient: inputDataOld.follow_up_patient,
      physician: "yes"
    },

  };
  const fetchCountry = async() => {
    let res = await axiosNew.get("/api/vle/appointment/country");
    if (res) {
      setCountry(res.data.data)
    }
  }
  const fetchStates = async() => {
    var country_id = document.getElementById("country").value;
    setSelectedCountry(country_id)
    let res = await axiosNew.get("/api/vle/appointment/state?country_id="+country_id);
    if (res) {
      setStates(res.data.data)
    }
  }
  const fetchCities = async() => {
    var state_id = document.getElementById("states").value;
    setSelectedState(state_id)
    let res = await axiosNew.get("/api/vle/appointment/city?state_id="+state_id);
    if (res) {
      setCity(res.data.data)
    }
  }

  const setCities = async() => {
    var city_id = document.getElementById("city").value;
    setSelectedCity(city_id)
  }
  const updateSalutation = async() => {
    var salutation = document.getElementById("salutation").value;
    setSalutation(salutation)
  }
  const oldPatientFunction = async () => {

  

    const oldformdata = new FormData();
    oldformdata.append("vle_report",formDataold.file);

    const upload = await axiosNew.post(
      "/api/vle/upload/patient/report",

      oldformdata,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  


    // setUrlImageold(upload.data.report_url);

    payload.patiant_data.patient_report=upload.data.report_url;

    if (payload.patiant_data.follow_up_patient == 'Yes') {
      const payloadfollow = {
        patient_id: payload.patient_id,
      };

      // const followapi = await axios.post("https://api.medyseva.com/api/vle/appointment/old_patient", payloadfollow);

      const followapi = await axiosNew.post("/api/vle/appointment/old_patient", payloadfollow);
      console.log(followapi.data.data.date, 'hdfhdhdbh');
      setpatientdate(followapi.data.data.date);
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      today = yyyy + '-' + mm + '-' + dd;
      console.log(today, 'todaydate')
      console.log(followapi.data.data.date, 'patientdateooo');
      const date1 = new Date(followapi.data.data.date);
      const date2 = new Date(today);
      const diffDays = (today, patientdate) => Math.ceil(Math.abs(today - patientdate) / (1000 * 60 * 60 * 24));

      // Example
      var datediffer = diffDays(date1, date2);

    }

    console.log(datediffer, 'diffdate--------------------');

    if (!payload.patiant_data.follow_up_patient) {
      setFromError("Please select your follow up type");
    }
    else if (payload.patiant_data.follow_up_patient == 'Yes' && datediffer > 7) {

      setFromError("follow up time finished");

    }
    else if (!payload.consultation_type) {
      setFromError("Please select consultation type");
    } else if (!payload.date) {
      setFromError("Please select date of appointment");
    } else if (!payload.patient_id) {
      setFromError("Please select patient name");
    }

    else {
      setFromError("");
      document.getElementById("oldpoint").style.pointerEvents = "none";
      try {
        setloadingdata((prev) => {

          return {
            ...prev,
            isloading: true,
          }
        })

        console.log(urlImageold,'yes exist data');
        // const api = await axios.post("https://api.medyseva.com/api/vle/appointment/create", payload);
        const api = await axiosNew.post("/api/vle/appointment/create",payload);


        if (api.data.status == 1) {
             console.log(api,'watch');
             setviewappoint(api.data.appointment.id);
          toast.success(api.data.msg);
          navigate('/appointment-view',{state:{appointid:api.data.appointment.id}});
          // setFormDataold('');

          setTimeout(() => {
            // window.location.reload();
          }, 2000);

          setloadingdata({
            isloading: false,
          })
          console.log(api.data.msg);
        } else {
          toast.error(api.data.msg);
        }

        setInputDataOld({
          temperature: "",
          pulse_rate: "",
          respiratory_rate: "",
          blood_pressure: "",
          height: "",
          weight: "",
          spo2: "",
          fbs: "",
          rbs: "",
          ppbs: "",
          blood_group: "",
          follow_up_patient: "",
          physician: "yes",
          patient_id: "",
          consultation_type: "",
          date: "",
        });
      } catch (error) {
        console.log(error, "ye old error");
      }
    }
  };

  // Old Patient appoinment code end

  // new Patient appoinment code start

  const [inputDataNew, setInputDataNew] = useState({
    preferred_language:"",
    name: "",
    email: "",
    mobile: "",
    age: "",
    dob:"",
    govt_id_proof: "",
    govt_id_detail: "",
    occupation: "",
    national_health_id: "",
    present_address: "",
    hear_about_medyseva: "",
    gender: genderData,
    patient_report: "",
    temperature: "",
    pulse_rate: "",
    respiratory_rate: "",
    blood_pressure: "",
    height: "",
    weight: "",
    spo2: "",
    fbs: "",
    rbs: "",
    ppbs: "",
    blood_group: "",
    follow_up_patient: "",
    physician: "yes",
  });

  const uploadFile = async () => {
    var file_d = document.getElementById('uploadFile').value;
    if (file_d == '') {

      toast.warn('please choose file');
    } else {



      newdata.append("vle_report", file);

      const upload = await axiosNew.post(
        // "https://api.medyseva.com/api/vle/upload/patient/report",
        "/api/vle/upload/patient/report",

        newdata,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      console.log(upload, "Url");
      toast.success("File upload successfully");
      setUrlImage(upload.data.report_url);


      setupload_img(true);


    }
  };

  const newformdata = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputDataNew({ ...inputDataNew, [name]: value });
  };

  const New_Patient_Payload = {
    vle_id: user.id,
    consultation_type: consultation_type1,
    date: dateOfAp,
    patient_type: "1",
    patiant_data: { ...inputDataNew },
  };

  let newpay = {
    vle_id: user.id,
    consultation_type: consultation_type1,
    date: dateOfAp,
    patient_type: "1",
    // user_id: user,
    preferred_language : inputDataNew.preferred_language,
    time_slot : slotTime,
    doctor_id : doctor,
    department : department,
    patiant_data: {
      salutation:salutation,
      name: inputDataNew.name,
      email: inputDataNew.email,
      mobile: inputDataNew.mobile,
      age: inputDataNew.age,
      dob: inputDataNew.dob,
      country:selectedCountry,
      state:selectedState,
      city:selectedCity,
      govt_id_proof: inputDataNew.govt_id_proof,
      govt_id_detail: inputDataNew.govt_id_detail,
      occupation: inputDataNew.occupation,
      national_health_id: inputDataNew.national_health_id,
      present_address: inputDataNew.present_address,
      hear_about_medyseva: inputDataNew.hear_about_medyseva,
      gender: genderData,
      patient_profile: urlImage,
      temperature: inputDataNew.temperature,
      pulse_rate: inputDataNew.pulse_rate,
      respiratory_rate: inputDataNew.respiratory_rate,
      blood_pressure: inputDataNew.blood_pressure,
      height: inputDataNew.height,
      weight: inputDataNew.weight,
      spo2: inputDataNew.spo2,
      fbs: inputDataNew.fbs,
      rbs: inputDataNew.rbs,
      ppbs: inputDataNew.ppbs,
      blood_group: inputDataNew.blood_group,
      follow_up_patient: 'No',
      physician: "yes",
      patient_report:"",
      patient_type:patientType
    }
  };
  const newPatientFunction = async () => {



    
    const newformdata = new FormData();
    newformdata.append("vle_report",formDatanew.file);

    const upload = await axiosNew.post(
      "/api/vle/upload/patient/report",

      newformdata,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  
    newpay.patiant_data.patient_report=upload.data.report_url;
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let chks = /^[ A-Za-z][A-z\s]*$/;
    console.log(New_Patient_Payload);
    console.log(file, "yha file aa rhi h");
    newdata.append("patient_report", file);
   

    if (!newpay.consultation_type) {
      setFromError("Please select consultation type");
    } else if (!newpay.date) {
      setFromError("Please select date of appointment");
    } else if (!newpay.patiant_data.name) {
      setFromError("Please enter patient name");
    }
    else if (!chks.test(newpay.patiant_data.name)) {
      setFromError("Not Allowed Numeric Value in Name");
    }

    else if (!regex.test(newpay.patiant_data.email)) {
      setFromError("Please enter your valid email");
    } else if (
      !newpay.patiant_data.mobile ||
      newpay.patiant_data.mobile.length < 10 ||
      newpay.patiant_data.mobile.length > 12
    ) {
      setFromError("Please enter your valid phone number");
    } else if (!newpay.patiant_data.age) {
      setFromError("Please enter patient age");
    } else if (!newpay.patiant_data.gender) {
      setFromError("Please select your gender");
    } 
    // else if (!newpay.patiant_data.patient_report) {
    //   setFromError("Please upload patient report");
    // }

    // else if (!newpay.patiant_data.temperature) {
    //   setFromError("Please enter body temperature new");
    // } else if (!newpay.patiant_data.pulse_rate) {
    //   setFromError("Please enter your pulse rate");
    // } else if (!newpay.patiant_data.respiratory_rate) {
    //   setFromError("Please enter your respiratory rate");
    // } else if (!newpay.patiant_data.blood_pressure) {
    //   setFromError("Please enter your blood pressure");
    // } else if (!newpay.patiant_data.height) {
    //   setFromError("Please enter your height");
    // } else if (!newpay.patiant_data.weight) {
    //   setFromError("Please enter your weight");
    // } else if (!newpay.patiant_data.spo2) {
    //   setFromError("Please enter your SPO2 level");
    // } else if (!newpay.patiant_data.follow_up_patient) {
    //   setFromError("Please select your follow up type");
    // } else if (!newpay.patiant_data.fbs) {
    //   setFromError("Please enter your FBS");
    // } else if (!newpay.patiant_data.rbs) {
    //   setFromError("Please enter your RBS");
    // } else if (!newpay.patiant_data.ppbs) {
    //   setFromError("Please enter your PPBS");
    // } else if (!newpay.patiant_data.blood_group) {
    //   setFromError("Please select your blood group");
    // }

    else {
      setFromError("");

      document.getElementById("newpoint").style.pointerEvents = "none";

      try {

        setloadingnewdata((prev) => {

          return {
            ...prev,
            isloading: true,
          }
        })
        // let pay2 = {
        //   vle_id: 98,
        //   consultation_type: consultation_type1,
        //   date: dateOfAp,
        //   patient_type: "1",
        //   patiant_data: { ...newpay }
        // }

        const api = await axiosNew.post(
          // "https://api.medyseva.com/api/vle/appointment/create",
          "/api/vle/appointment/create",

          newpay
        );
        console.log(api);
        if (api.data.status == 1) {
          toast.success(api.data.msg);

          // navigate('/appoint_list');

          navigate('/appointment-view',{state:{appointid:api.data.appointment.id}});


          setTimeout(() => {
            // window.location.reload();
          }, 2000);


          setloadingnewdata({
            isloading: false,
          });

          document.getElementById('uploadFile').value = '';
          setFormData('');
          console.log(api.data.msg);
        } else {
          toast.error(api.data.msg);
        }
        setInputDataNew({
          consultation_type: "",
          date: "",
          salutation:"",
          name: "",
          email: "",
          mobile: "",
          age: "",
          govt_id_proof: "",
          govt_id_detail: "",
          occupation: "",
          national_health_id: "",
          present_address: "",
          hear_about_medyseva: "",
          gender: genderData,
          patient_report: "",
          temperature: "",
          pulse_rate: "",
          respiratory_rate: "",
          blood_pressure: "",
          height: "",
          weight: "",
          spo2: "",
          fbs: "",
          rbs: "",
          ppbs: "",
          blood_group: "",
          follow_up_patient: "",
          physician: "yes",
        });
      } catch (error) {
        console.log(error, 'ye rha error');
      }
    }
  };

  useEffect(() => {
    var currentDtas = new Date();
    var ddas = String(currentDtas.getDate()).padStart(2, '0');
    var mmas = String(currentDtas.getMonth() + 1).padStart(2, '0');
    var yyyyas = currentDtas.getFullYear();

    currentDtas = yyyyas + '-' + mmas + '-' + ddas;
    document.getElementById('appoint_date').setAttribute('min', currentDtas);

  });
  // useEffect(() => {

  //   if (inputDataOld.follow_up_patient == 'Yes') {

  //     document.getElementById('old_pat').style.display = 'block';


  //   } else {
  //     document.getElementById('old_pat').style.display = 'none';
  //   }


  // }, [inputDataOld.follow_up_patient]);

  console.log('opop--direct');
  return (
    <Dashboard_layout>
      <ToastContainer />
      <div className="content-top-padding">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary mt-2">
              Add Appointment
            </h6>
          </div>
          <div className="box-body">
            {/* common code for new and old patient start */}

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="radio radio-info radio-inline mt-10 d-flex">
                    {/* <input
                      type="radio"
                      id="g1"
                      onChange={(e) => setconsultation_type1(e.target.value)}
                      value="1"
                      className="c_type"
                      checked={consultation_type1 == '1' ? true : false}
                      name="Consultation"
                    ></input>
                    <label for="g1" style={{ fontWeight: 'bold' }}> General Physician Consultation</label> */}
                    &emsp;
                    <input
                      type="radio"
                      id="s1"
                      onChange={(e) => setconsultation_type1(e.target.value)}
                      value="2"
                      className="c_type"
                      checked={consultation_type1 == '2' ? true : false}

                      name="Consultation"
                    ></input>
                    <label for="s1" style={{ fontWeight: 'bold' }}> Specialist Consultation</label>
                    &emsp;
                    {/* <input
                      type="radio"
                      id="ss1"
                      onChange={(e) => setconsultation_type1(e.target.value)}
                      value="3"
                      className="c_type"
                      checked={consultation_type1 == '3' ? true : false}

                      name="Consultation"
                    ></input>
                    <label for="ss1" style={{ fontWeight: 'bold' }}>Super Specialist Consultation</label> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group plr-10">
                  <label>Select Department </label>
                  <select
                        className="form-select"
                        name="department_id"
                        id="department_id"
                        onChange={getDoctorByDepartment}
                        aria-label="Default select example"
                      >
                        <option value="">Select Name</option>
                        {departments.length > 0
                          ? departments &&
                          departments?.map((items, index) => {
                            return (
                              <option value={items.id}>{items.name}</option>
                            );
                          })
                          : "Data Not Found"}
                        {/* <option selected>Select</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option> */}
                      </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group plr-10">
                  <label>Select Doctor <small> (optional)</small></label>
                  <select
                        className="form-select"
                        name="doctor"
                        id="doctor"
                        onChange={getDoctorsAppointment}
                        value={inputDataOld.user_id}
                        aria-label="Default select example"
                      >
                        <option value="">Select Doctor</option>
                        {doctors.length > 0
                          ? doctors &&
                          doctors?.map((items, index) => {
                            return (
                              <option value={items.id}>{items.name}</option>
                            );
                          })
                          : "Data Not Found"}
                        {/* <option selected>Select</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option> */}
                      </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group plr-10">
                  
                  <button
                        className="btn btn-primary mt-3"
                        disabled={upload_img}
                        onClick={checkAvailability}
                        type="button"

                      >
                        View Availability
                      </button>
                  
                </div>
              </div>
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Select Appointment Slot</Modal.Title>
              </Modal.Header>
              <Modal.Body>           
                <FullCalendar   
                  events={events}   
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView="timeGridWeek"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  slotMinTime="09:00:00"
                  slotMaxTime="18:00:00"
                  slotDuration = "00:60:00"
                  expandRows = {true}            
                  headerToolbar= {{
                    start: 'prev,next',
                    center: 'title',
                    right: '' // user can switch between the two
                  }}
                  select ={function(info) {
                    alert('selected ' + info.startStr + ' to ' + info.endStr);
                    setAppointmentDateAndTime(info.startStr,info.endStr)
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>                
              </Modal.Footer>
            </Modal>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Date *</label>
                  <div className="input-group">

                    <input
                      type="date"
                      onChange={(e) => setDateOfAp(e.target.value)}
                      className="form-control"
                      id="appoint_date"
                      name="date"
                      value={dateOfAp}
                      // isValidDate={disablePastDt}
                      // min="2022-10-14"
                      autocomplete="off"
                    ></input>
                  </div>
                </div>                              
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Time Slot <small>(optional)</small></label>
                  <div className="input-group">

                    <input readonly
                      type="text"
                    
                      className="form-control"
                      id="slot_time"
                      name="slot_time"
                      value={slotTime}
                      // isValidDate={disablePastDt}
                      // min="2022-10-14"
                      autocomplete="off"
                    ></input>
                  </div>
                </div>
              </div>
            
              <div className="col-md-12">
                <div id="cat-form">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <div className="radio radio-info radio-inline mt-10 d-flex">
                          <input
                            type="radio"
                            id="old"
                            onChange={(e) => setPatientType(e.target.value)}
                            value="Old Patient"
                            checked={activeCheck}
                            className="p_type"
                            name="p_type"
                          ></input>
                          <label for="old" style={{ fontWeight: 'bold' }}> Old Patient</label>&emsp;
                          <input
                            type="radio"
                            onChange={(e) => setPatientType(e.target.value)}
                            id="new1"
                            value="New Patient"
                            className="p_type"
                            name="p_type"
                          ></input>
                          <label for="new1" style={{ fontWeight: 'bold' }}> New Patient</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* common code for new and old patient start */}

                {/* old patient section start */}

                <div className="row" id="old_patient">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Patient <span>*</span>
                      </label>
                      <select
                        className="form-select"
                        name="patient_id"
                        onChange={formdata}
                        value={inputDataOld.patient_id}
                        aria-label="Default select example"
                      >
                        <option value="">Select Name</option>
                        {patientList.length > 0
                          ? patientList &&
                          patientList?.map((items, index) => {
                            return (
                              <option value={items.id}>{items.name}</option>
                            );
                          })
                          : "Data Not Found"}
                        {/* <option selected>Select</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Temperature </label>
                        <input
                          type="number"
                          className="form-control"
                          name="temperature"
                          onChange={formdata}
                          value={inputDataOld.temperature}
                          placeholder="Temperature"
                          aria-invalid="false"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Pulse </label>
                        <input
                          type="number"
                          className="form-control"
                          name="pulse_rate"
                          onChange={formdata}
                          value={inputDataOld.pulse_rate}
                          Placeholder="Puls"
                          autocomplete="off"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Respiratory rate </label>
                        <input
                          type="number"
                          className="form-control"
                          name="respiratory_rate"
                          onChange={formdata}
                          value={inputDataOld.respiratory_rate}
                          Placeholder="Respiratory rate"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Blood pressure </label>
                        <input
                          type="text"
                          className="form-control"
                          name="blood_pressure"
                          onChange={formdata}
                          value={inputDataOld.blood_pressure}
                          Placeholder="Blood pressure"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Height </label>
                        <input
                          type="number"
                          className="form-control"
                          name="height"
                          onChange={formdata}
                          value={inputDataOld.height}
                          Placeholder="Height"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Weight </label>
                        <input
                          type="number"
                          className="form-control"
                          name="weight"
                          onChange={formdata}
                          value={inputDataOld.weight}
                          Placeholder="Weight"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>SPO2 </label>
                        <input
                          type="number"
                          className="form-control"
                          name="spo2"
                          onChange={formdata}
                          value={inputDataOld.spo2}
                          Placeholder="SPO2"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Follow Up Patientss </label>
                        <select
                          id="payment_mode"
                          name="follow_up_patient"
                          onChange={formdata}
                          value={inputDataOld.follow_up_patient}
                          className="form-control"
                        >
                          <option>Select type</option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-3" id="old_pat">
                      <div className="form-group plr-10">
                        <label>Patient Report  <a target="_blank" href={formDataold.photoUrl} >{formDataold.photoUrl}</a></label>

                        <input
                          type="file"
                          className="form-control"
                          name="patient_report"

                          // onChange={formdata}
                          // value={inputDataOld.patient_report}
                          Placeholder="Patient report"

                          onChange={(e) =>
                            photoUploadHandler_old(e, setFormDataold)
                          }
                          ondragover="drag()"
                          ondrop="drop()"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>FBS sd</label>
                        <input
                          type="number"
                          className="form-control"
                          name="fbs"
                          onChange={formdata}
                          value={inputDataOld.fbs}
                          Placeholder="FBS"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>RBS </label>
                        <input
                          type="number"
                          className="form-control"
                          name="rbs"
                          onChange={formdata}
                          value={inputDataOld.rbs}
                          Placeholder="RBS"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>PPBS </label>
                        <input
                          type="number"
                          className="form-control"
                          name="ppbs"
                          onChange={formdata}
                          value={inputDataOld.ppbs}
                          Placeholder="PPBS"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Blood Group </label>
                        <select
                          id="payment_mode"
                          name="blood_group"
                          onChange={formdata}
                          value={inputDataOld.blood_group}
                          className="form-control"
                        >
                          <option>select Type</option>
                          <option value="A+">A Positive</option>
                          <option value="A-">A Negative</option>
                          <option value="AB+">AB Positive</option>
                          <option value="AB-">AB Negative</option>
                          <option value="B+">B Positive</option>
                          <option value="B-">B Negative</option>
                          <option value="O+">O Positive</option>
                          <option value="O-">O Negative</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Preferred language </label>
                        <select
                          className="form-select"
                          name="preferred_language"
                          onChange={formdata}
                          value={inputDataOld.preferred_language}
                          aria-label="Default select example"
                        >
                          <option value="">Select Language</option>
                                            
                          <option value="marathi">Marathi</option>
                          <option value="hindi">Hindi</option>
                          <option value="english">English</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <h3
                    style={{
                      color: "red",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    {fromError}
                  </h3>
                  <div className="col-sm-3">
                    <div className="form-btn">
                      <a
                        // style={{ cursor: "pointer" }}
                        onClick={() => {
                          oldPatientFunction();
                        }}
                        id="oldpoint"
                        style={{ pointerEvents: loadingdata.isloading == true ? 'none' : 'auto',cursor: "pointer" }}

                      >
                        <i className="fa fa-check"></i>{loadingdata.isloading == true ? 'Processing' : 'Add Appointments'}
                      </a>
                    </div>
                  </div>
                </div>

                {/* old patient section end */}

                {/* New patient section start */}

                <div className="row" id="new_patient">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Salutation<span>*</span>
                      </label>
                      <select
                        className="form-select"
                        name="hear_about_medyseva"
                        onChange={updateSalutation}
                        value={salutation}
                        id="salutation"
                        aria-label="Default select example"
                      >
                        <option selected>Select Salutation</option>
                        <option value="mr">Mr</option>
                        <option value="miss">Miss</option>
                        <option value="mrs">Mrs</option>
                      </select>

                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Name<span>*</span>
                      </label>
                      <input
                        pattern="^[a-zA-Z]"
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={newformdata}
                        value={inputDataNew.name}
                        placeholder="Enter Name"
                        aria-invalid="false"

                      ></input>

                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Email<span>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={newformdata}
                        value={inputDataNew.email}
                        placeholder="Enter Email"
                        aria-invalid="false"
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Phone<span>*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="mobile"
                        onChange={newformdata}
                        value={inputDataNew.mobile}
                        placeholder="Enter Number"
                        aria-invalid="false"
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Date of Birth<span>*</span>
                      </label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        name="age"
                        onChange={newformdata}
                        value={inputDataNew.dob}
                        placeholder="Enter Your Age"
                        aria-invalid="false"
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Age<span>*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="age"
                        onChange={newformdata}
                        value={inputDataNew.age}
                        placeholder="Enter Your Age"
                        aria-invalid="false"
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Country<span>*</span>
                      </label>
                      <select
                        className="form-select"
                        name="hear_about_medyseva"
                        onChange={fetchStates}
                        value={selectedCountry}
                        id="country"
                      >
                        <option selected>Select Country</option>
                        { 
                          country.map((item)=>{
                            return (<option value={item.id}>{item.name}</option>)
                          })
                        }
                        
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        State<span>*</span>
                      </label>
                      <select
                        className="form-select"
                        name="hear_about_medyseva"
                        onChange={fetchCities}
                        value={selectedState}
                        id="states"
                      >
                        <option selected>Select State</option>
                        { 
                          state.map((item)=>{
                            return (<option value={item.id}>{item.name}</option>)
                          })
                        }
                      </select>
                    </div>
                  </div>
                  
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        City<span>*</span>
                      </label>
                      <select
                        className="form-select"
                        name="hear_about_medyseva"
                        onChange={setCities}
                        value={selectedCity}
                        id="city"
                      >
                        <option selected>Select City</option>
                        { 
                          city.map((item)=>{
                            return (<option value={item.id}>{item.name}</option>)
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Govt ID Proof <span>(Optional)</span>
                      </label>
                      <select
                        className="form-select"
                        name="govt_id_proof"
                        onChange={newformdata}
                        value={inputDataNew.govt_id_proof}
                        aria-label="Default select example"
                      >
                        <option selected>Select</option>
                        <option value="1">Aadhar</option>
                        <option value="2">DL</option>
                        <option value="3">Pan Card</option>
                        <option value="3">PassPort</option>
                      </select>
                      <input
                        type="number"
                        className="form-control"
                        name="govt_id_detail"
                        onChange={newformdata}
                        value={inputDataNew.govt_id_detail}
                        placeholder="Govt ID Details"
                        aria-invalid="false"
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        National Health Id<span> (Optional)</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="national_health_id"
                        onChange={newformdata}
                        value={inputDataNew.national_health_id}
                        placeholder="National Health Id"
                        aria-invalid="false"
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Occuptation<span> (Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="occupation"
                        onChange={newformdata}
                        value={inputDataNew.occupation}
                        placeholder="National Health Id"
                        aria-invalid="false"
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Present Address<span> (Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="present_address"
                        onChange={newformdata}
                        value={inputDataNew.present_address}
                        placeholder="National Health Id"
                        aria-invalid="false"
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        How did you hear about Medyseva ?
                        <span> (Optional)</span>
                      </label>
                      <select
                        className="form-select"
                        name="hear_about_medyseva"
                        onChange={newformdata}
                        value={inputDataNew.hear_about_medyseva}
                        aria-label="Default select example"
                      >
                        <option selected>Select</option>
                        <option value="WebSite">WebSite</option>
                        <option value="Instagram">Instagram</option>
                        <option value="FaceBook">FaceBook</option>
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Headinges">Headinges</option>
                        <option value="Pamphlet">Pamphlet</option>
                        <option value="World Of Mouth">World Of Mouth</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Other">Other</option>
                        <option value="School">School</option>
                        <option value="Vehicle">Vehicle</option>
                        <option value="No Reply">No Reply</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Gender<span></span>
                      </label>
                      <div className="radio radio-info radio-inline mt-10 d-flex">
                        <input
                          type="radio"
                          id="male1"
                          value="1"
                          onChange={(e) => setGenderData(e.target.value)}
                          className="g_type"
                          name="gender"
                        ></input>
                        <label for="male1">Male</label>&emsp;
                        <input
                          type="radio"
                          id="female"
                          onChange={(e) => setGenderData(e.target.value)}
                          value="2"
                          className="g_type"
                          name="gender"
                        ></input>
                        <label for="female">Female</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                      Profile Photo<span></span>
                      </label>
                      <div class="form-group">
                        <div id="preview" class="preview-box-new">
                          {!user.profilePic ? (
                            <img src={photoUrl} style={{ width: "100px" }} />
                          ) : (
                            <img src={photoUrl} style={{ width: "100px" }} />
                          )}
                        </div>
                        <div class="uploadOuter">
                          <span class="dragBox upload-NFT-box create-upload-box upload-new-box">
                            <div class="upload-NFT-box-inner">
                              Upload Picture
                            </div>
                            <input
                              type="file"
                              onChange={(e) =>
                                photoUploadHandler(e, setFormData)
                              }
                              ondragover="drag()"
                              ondrop="drop()"
                              id="uploadFile"
                            />{" "}
                          </span>
                        </div>
                      </div>
                      <button
                        style={{ backgroundColor: "#053479", color: "white" }}
                        disabled={upload_img}
                        onClick={() => {
                          uploadFile();
                        }}


                      >
                        click to upload
                      </button>
                      {/* <input type="file" className="form-control" name="t" placeholder="National Health Id" value="" aria-invalid="false"></input> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Temperature</label>
                        <input
                          type="number"
                          className="form-control"
                          name="temperature"
                          onChange={newformdata}
                          value={inputDataNew.temperature}
                          placeholder="Temperature"
                          aria-invalid="false"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Pulse</label>
                        <input
                          type="number"
                          className="form-control"
                          name="pulse_rate"
                          onChange={newformdata}
                          value={inputDataNew.pulse_rate}
                          Placeholder="Puls"
                          autocomplete="off"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Respiratory rate</label>
                        <input
                          type="number"
                          className="form-control"
                          name="respiratory_rate"
                          onChange={newformdata}
                          value={inputDataNew.respiratory_rate}
                          Placeholder="Respiratory rate"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Blood pressure</label>
                        <input
                          type="text"
                          className="form-control"
                          name="blood_pressure"
                          onChange={newformdata}
                          value={inputDataNew.blood_pressure}
                          Placeholder="Blood pressure"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Height</label>
                        <input
                          type="number"
                          className="form-control"
                          name="height"
                          onChange={newformdata}
                          value={inputDataNew.height}
                          Placeholder="Height"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Weight</label>
                        <input
                          type="number"
                          className="form-control"
                          name="weight"
                          onChange={newformdata}
                          value={inputDataNew.weight}
                          Placeholder="Weight"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>SPO2</label>
                        <input
                          type="number"
                          className="form-control"
                          name="spo2"
                          onChange={newformdata}
                          value={inputDataNew.spo2}
                          Placeholder="SPO2"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3" style={{ display: 'none' }}>
                      <div className="form-group plr-10">
                        <label>Follow Up Patient</label>
                        <select
                          id="payment_mode"
                          name="follow_up_patient"
                          onChange={newformdata}
                          value={inputDataNew.follow_up_patient}
                          className="form-control"
                        >
                          <option>select type</option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3" id="old_pat">
                      <div className="form-group plr-10">
                        <label>Patient Report  <a target="_blank" href={formDatanew.photoUrl} >{formDatanew.photoUrl}</a></label>

                        <input
                          type="file"
                          className="form-control"
                          name="patient_report"

                          // onChange={formdata}
                          // value={inputDataOld.patient_report}
                          Placeholder="Patient report"

                          onChange={(e) =>
                            photoUploadHandler_new(e, setFormDatanew)
                          }
                          ondragover="drag()"
                          ondrop="drop()"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>FBS</label>
                        <input
                          type="number"
                          className="form-control"
                          name="fbs"
                          onChange={newformdata}
                          value={inputDataNew.fbs}
                          Placeholder="FBS"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>RBS</label>
                        <input
                          type="number"
                          className="form-control"
                          name="rbs"
                          onChange={newformdata}
                          value={inputDataNew.rbs}
                          Placeholder="RBS"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>PPBS</label>
                        <input
                          type="number"
                          className="form-control"
                          name="ppbs"
                          onChange={newformdata}
                          value={inputDataNew.ppbs}
                          Placeholder="PPBS"
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Blood Group</label>
                        <select
                          name="blood_group"
                          onChange={newformdata}
                          value={inputDataNew.blood_group}
                          id="payment_mode"
                          className="form-control"
                        >
                          <option>select type</option>
                          <option value="A+">A Positive</option>
                          <option value="A-">A Negative</option>
                          <option value="AB+">AB Positive</option>
                          <option value="AB-">AB Negative</option>
                          <option value="B+">B Positive</option>
                          <option value="B-">B Negative</option>
                          <option value="O+">O Positive</option>
                          <option value="O-">O Negative</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group plr-10">
                        <label>Preferred language </label>
                        <select
                          className="form-select"
                          name="preferred_language"
                          onChange={formdata}
			  value={inputDataNew.preferred_language}
                          aria-label="Default select example"
                        >
                          <option value="">Select Language</option>                          
                          <option value="marathi">Marathi</option>
                          <option value="hindi">Hindi</option>
                          <option value="english">English</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <h3
                    style={{
                      color: "red",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    {fromError}

                  </h3>
                  <div className="col-sm-3">
                    <div className="form-btn">
                      <a
                        // style={{ cursor: "pointer" }}
                        // className={onttimeclick ? 'stopuse' : ''}
                        onClick={() => {
                          newPatientFunction();
                        }}
                        id="newpoint"
                        style={{ pointerEvents: loadingdata.isloading == true ? 'none' : 'auto',cursor: "pointer" }}
                      >
                        <i className="fa fa-check"></i>{loadingnewdata.isloading == true ? 'Processing' : 'Add Appointment'}
                      </a>
                    </div>
                  </div>
                </div>

                {/* New patient section end */}
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* <script src="./../../../public/assets/js/external.js" type="text/javascript" /> */}
    </Dashboard_layout>
    

  );
};

export default Appointment_create;
