import { useState } from "react";
import { useClient } from "./settings";
import { Grid, Button } from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
export default function Controls(props) {

  const navigate=useNavigate();
//   const agdata=localStorage.getItem('agoraaddress');
// const agdata1=JSON.parse(agdata);
//   console.log(agdata1.appid.channel_name,'ksdjhsjdsj')
//   console.log(agdata1,'ksdjfgfgrrtrtrtrthsjdsj')

  // const appId = "58f05d2722fc430181051bddbe210492";
  // const token =
  // "00658f05d2722fc430181051bddbe210492IABdotf7Xa8X4jcAoQDpu+Sh7s/unCF/HeYavIHg55qjtylTJdAAAAAAIgA/HQEA3u3UYwQAAQBuqtNjAwBuqtNjAgBuqtNjBABuqtNj";
  // export const config = { mode: "rtc", codec: "vp8", appId: appId, token: token };
  // export const useClient = createClient(config);
//   const authagora = useSelector((state) => state.auth.agoravalue.agoravalue);
// console.log(authagora,'sfsf')
  const client = useClient();
  const { tracks, setStart, setInCall } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
    localStorage.setItem('agoraaddress','');
    navigate('/dashboard')
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Button
          variant="contained"
          color={trackState.audio ? "primary" : "secondary"}
          onClick={() => mute("audio")}
        >
          {trackState.audio ? <MicIcon /> : <MicOffIcon />}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color={trackState.video ? "primary" : "secondary"}
          onClick={() => mute("video")}
        >
          {trackState.video ? <VideocamIcon /> : <VideocamOffIcon />}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="default"
          onClick={() => leaveChannel()}
        >
          Leave
          <ExitToAppIcon />
        </Button>
      </Grid>
    </Grid>
  );
}
