import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Dashboard_layout from "../Layout/Dashboard_layout";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axiosMain from "../../http/axios/axios_main";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import ReactPaginate from "react-paginate";
import axiosNew from './../../http/axios/axios_new';
import { useDispatch } from "react-redux";
import { agoraids } from "../../reducer/authReducers";
import helpers from "../../Helper/CryptoHelper";
const Dashboard = () => {

const dispatch=useDispatch()
  const { state } = useLocation("/");
// console.log(state);
const [log,setlog]=useState();
if(state!=null){
  // const { logalert } = state;

  // setlog(state.logalert);


}



  console.log(state,'opopopopopop');

  const navigate = useNavigate();

  const { user, isAuthenticated } = useAuth();
  //const user1 = user._id
  //console.log(user1,"user_id");

  const [apiData, setApiData] = useState({
    isloading: false,
    data: [],
    error: "",
  });

  // const [usersdata,setusersdata]=useState(apiData.data.slice(0,4));

  // console.log(usersdata,'ooootttt');
  const [patient, setPatient] = useState(false);

  const [appointment, setAppointment] = useState(false);

  const [doctor, setDoctor] = useState(false);

  const [show, setShow] = useState(false);
  const [walletshow, setwalletshow] = useState(false);



  const [vlepatients, setvlepatients] = useState(0);
  const [vleappoitments, setvleappoitments] = useState(0);
  const [vlebalance, setvlebalance] = useState(0);
  const [vleTodayAppointment, setVleTodayAppointment] = useState(0);

  const [searchTerm, setsearchTerm] = useState("");

  // const [pageNumber, setPageNumber] = useState(0);
  // const usersPerPage = 10;
  // const pageVisited = pageNumber * usersPerPage;



  const [inputdata, setInputdata] = useState({
    pagecount: 30,

  });


  const formdata = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputdata({ ...inputdata, [name]: value });
  };

  // const [precription_id, setprecription_id] = useState('');

  const getData = async () => {
    try {
      setApiData((prev) => {
        return {
          ...prev,
          isloading: true,
        };
      });


      // const api = await axios.post("https://api.medyseva.com/api/vle-today-appointment", {

      const api = await axiosNew.post("/api/vle-today-appointment", helpers.encrypt(JSON.stringify({
        user_id: user.id,
        limit: inputdata.pagecount,
        offset: 0,
      })))
      
      api.data = helpers.decrypt(JSON.stringify(api.data));
      //console.log(api.data.original.data, "preshelpjoin");
      setApiData({
        isloading: false,
        data:api.data.original.data,
        error: "",
      });
      console.log(api.data);
    } catch (error) {
      setApiData({
        isloading: false,
        data: [],
        error: error.data,
      });
    }

if(user.wallet_amount < 500  && state!=null){
setwalletshow(true);

}
  };
  useEffect(() => {
    getData();
  }, [inputdata.pagecount]);

  const handleAppointment = async (appointData) => {
    console.log("data---aponitdash", appointData);

    // let res = await axios.post("https://api.medyseva.com/api/vle-appointment-details", {
      let res = await axiosNew.post("/api/vle-appointment-details", {

      id: appointData.id,
    })


    console.log("data--mam-d", res.data);
    if (res.data.status == 1) {

      setPatient(res.data.patient);
      setAppointment(res.data.appointment);
      setDoctor(res.data.doctor);

      setShow(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleprescription = (data) => {
    console.log(data.prescription_id, "pid");

    navigate("/prescription", { state: { presid: data.prescriptions[0].id } });
  };

  const handlegeninvoice = (data) => {
    console.log(data.prescription_id, 'p----id');

    navigate("/invoice-generate", {
      state: {
        presid: data.prescriptions[0] == null || data.prescriptions[0] == "" ? 0 : data.prescriptions[0].id,
        vleid: data.added_by,
        apointid: data.id,
        // invoiceid: data.invoice.id,
      },
    });
  };


  const getData_today = async () => {
    let inputdata = {
      user_id: user.id,
    };
    let input_value = {
      user_id: user.id,
    };
    let res_data = await axiosMain.post("/api/vle-dashboard", helpers.encrypt(JSON.stringify(input_value)))
    res_data.data = helpers.decrypt(JSON.stringify(res_data.data));
    console.log(res_data.data.original.data, "fine");
    if (res_data.data.original.status == 1) {
      setvlebalance(res_data.data.original.data.balance);
      setvleappoitments(res_data.data.original.data.appointments);
      setvlepatients(res_data.data.original.data.patients);
      setVleTodayAppointment(res_data.data.original.data.today_appointment);
    }
  };
  // console.log("patients", patients);
  useEffect(() => {
    getData_today();
  }, []);

  const createinvoice = async (data) => {

    // var prescripid_create = '';
    // data.prescription.map((items) => prescripid_create = items.id)
   
    navigate("/createinvoice", { state: { prescriptionid: data.prescriptions[0].id } });
  };

  const wallethandleClose=()=>{

    setwalletshow(false);
  }



  const agora_screen=(data)=>{
    localStorage.setItem("agoraaddress", JSON.stringify(data));
  
    // dispatch(
    //   agoraids({
    //     user: {
    //       appid:data
    //     },
    //   })
    // );
  window.location.href='/video'
    // navigate('/video');
  }
  return (
    <Dashboard_layout>
      <ToastContainer />

      <div className="content-top-padding">

        <div className=" pt-2 pb-4">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i className="fas fa-hospital"></i>
                <span>{vleTodayAppointment}</span>
                <h6>Today's Appointments</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i className="fas fa-user"></i>
                <span>{vlepatients}</span>
                <h6>Total Patients</h6>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i className="fas fa-user"></i>
                <span>{vleappoitments}</span>
                <h6>Total Appointment</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i className="fas fa-repeat-1-alt"></i>
                <span>{vlebalance}</span>
                <h6>Total Balance</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary mt-2 pull-left">
              Today's Appointments
            </h6>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="drop-flex">
                  <p>Show </p>
                  <select onChange={formdata}
                    name="pagecount"
                    value={inputdata.pagecount}
                    className="form-select"
                    aria-label="Default select example"
                  >
                    {/* <option selected>10</option> */}
                 
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                  </select>
                  <p>Entries</p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="drop-flex">
                  <label>Search:</label>
                  <input
                    onChange={(event) => { setsearchTerm(event.target.value) }}
                    type="search"
                    className="form-control input-sm"
                    placeholder="Search by name..."
                    aria-controls="appoinment_table"
                  ></input>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table
                className="table table-bordered vle-table"
                id="dataTable"
                width="100%"
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th width="20%">Patient Info</th>

                    <th>Schedule Info</th>
                    <th>Appointment type</th>

                    <th>Payment Status</th>
                    <th> Status</th>
                    <th width="20%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.isloading && (
                    <tr>
                      <td
                        style={{ textAlign: "center", color: "red" }}
                        colSpan="6"
                      >
                        Data loading
                      </td>
                    </tr>
                  )}
                  {!apiData.isloading && apiData.data.length > 0
                    ?

                    apiData.data &&
                    apiData?.data?.filter((items) => {
                      if (searchTerm == "") {
                        return items;

                      } else if (items.patient.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return items;
                      }
                    }).map((items, index) => {
                      return (
                        <tr role="row" className="odd">
                          <td>{index + 1}</td>
                          <td>
                            <p>
                              <strong>Name : </strong>
                              {items.patient.name}
                              <span>({items.patient.mr_number})</span>
                            </p>
                            <p>
                              <strong>Phone : </strong>
                              {items.patient.mobile}
                            </p>
                            <p>
                              <strong>Email : </strong>
                              {items.patient.email}
                            </p>
                          </td>

                          <td>
                            <label className="badge badge-primary-soft brd-20">
                              <i className="fa fa-calendar"></i>&nbsp;{" "}
                              {items.date}{" "}
                            </label>
                          </td>
                          <td>{items.appointment_type==1 ? 'General' : 'Special'}</td>

                          <td>
                            <label className="badge badge-danger-soft brd-20" style={{ background: '#c8efd1c9', color: '#28a745' }}>
                              {" "}
                              Paid
                            </label>
                          </td>
                          <td>Success</td>
                          <td>
                            <div style={{display:"flex"}}>
                           {items.payment_user == null && items.follow_up != 'Yes' ? (

                            <a
                              onClick={(e) => handlegeninvoice(items)}
                              className="btn btn-light-secondary btn-sm"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Create Invoice"
                              id="in_voice"
                            >
                              <i className="fas fa-paper-plane"></i>
                            </a>
                             ) : (
                              <div></div>
                            )} 

                            {/* <a
                              onClick={(e) => handlegeninvoice(items)}
                                className="btn btn-light-secondary btn-sm"
                                href=""
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Create Invoice"
                              >
                                <i className="fas fa-paper-plane"></i>
                              </a> */}
                              {/* {items.doctor == null ? ( */}
                            <a
                              className="btn btn-primary btn-sm start_meeting"
                              href={items.video_link}
                              data-id="7039"
                              // target="_blank"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Video Link"
                              // onClick={()=>agora_screen(items)}

                              target="_blank"
                            >
                              <i className="fas fa-video"></i>
                            </a>
                                {/* ) : (
                                  <div></div>
                                )} */}

                            <a
                              // href="#"
                              onClick={(e) => handleAppointment(items)}
                              className="btn btn-light-secondary btn-sm"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="View"
                            >
                              <i className="far fa-eye"></i>
                            </a>

                            {items.prescriptions != '' ? (
                              <a
                             
                                onClick={(e) => handleprescription(items)}
                                className="btn btn-light-secondary btn-sm"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Prescription View"
                              >
                                <i className="far fa-address-card">
                                  {items.payment_user != null
                                    ? items.payment_user.invoice_id
                                    : ""}
                                </i>
                              </a>
                            ) : (
                              <div></div>
                            )}


                               {items.invoice_lab == null  && items.prescriptions != '' ? (
                                         <a
                                    onClick={(e) => createinvoice(items)}
                                    className="btn btn-light-secondary btn-sm"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Create Investigation Invoice"
                                    placeholder="Create Investigation Invoice"
                                  >
                                    <i className="fa fa-address-book"></i>
                                  </a>
                                  ) : (
                              <div></div>
                            )}


                            {/* {items.invoice != null ? (
                              <div></div>
                            ) : (
                              <a
                                onClick={(e) => handlecreateinvoice(items)}
                                className="btn btn-light-secondary btn-sm"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="View"
                              >
                                <i className="far fa-plus"></i>
                              </a>
                            )} */}
                            </div>
                          
                          </td>
                        </tr>
                      );
                    })
                    : ""}
                </tbody>
              </table>
            
            </div>
          </div>
        </div>
      </div>
      {/* <section id="appoitment">
	<div className="row_container">
		<div className="col-md-12">
			<div className="box add_area for-desktop-only">
				<div className="box-header with-border d-flex justify-content-between">
					<h3 className="box-title">Appointments </h3>
					<div className="box-tools pull-right">
						<a href="/appointment_create" className='btn btn-primary'>create</a>
					</div>
				</div>
				<div className="box-body">
					<div className="row">
						<div className="col-sm-6">
							<div className="drop-flex">
								<p>Show </p>
								<select className="form-select" aria-label="Default select example">
									<option selected>10</option>
									<option value="1">11</option>
									<option value="2">12</option>
									<option value="3">13</option>
								</select>
								<p>entries</p>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="drop-flex">
								<label>Search:</label>
								<input type="search" className="form-control input-sm" placeholder="" aria-controls="appoinment_table"></input>
							</div>
						</div>
						<div className="col-sm-12">
							<table id="appoinment_table" className="table table-bordered datatable table-responsive">
								<thead>
									<tr>
										<th>#</th>
										<th width="20%">Patient Info</th>
										
										<th>Schedule Info</th>
										<th>Payment Status</th>
										<th>Doctor</th>
										<th width="20%">Action</th>
									</tr>
								</thead>
								<tbody>
										{apiData.length > 0 ? apiData && apiData.map((items , index)=>{
									return(
									<tr role="row" className="odd">

										<td>{index + 1}</td>
										<td>
											<p><strong>Name : </strong>{items.name}<span>({items.patient_id})</span></p>
											<p><strong>Phone : </strong>{items.mobile}</p>
											<p><strong>Email : </strong>{items.email}</p>
										</td>
										
										<td><label className="badge badge-primary-soft brd-20">
											<i className="fa fa-calendar"></i>&nbsp; {items.date} </label>
										</td>
										<td><label className="badge badge-danger-soft brd-20"> Paid</label></td>
										<td>Mohit Sood</td>
										<td>
											<a className="btn btn-light-secondary btn-sm" href="" data-toggle="tooltip" data-placement="top" title="Create Invoice" target="_blank">
											<i className="fas fa-paper-plane"></i>
											</a>
											<a className="btn btn-primary btn-sm start_meeting" href={items.video_link} data-id="7039" data-toggle="tooltip" data-placement="top" title="Video Link" target="_blank">
											<i className="fas fa-video"></i>
											</a>

											<a href="#"  onClick={(e)=>handleAppointment(items)} className="btn btn-light-secondary btn-sm"  data-toggle="tooltip" data-placement="top" title="View">
											<i className="far fa-eye"></i>
											</a>
										</td>
									</tr>
											)}) : "data not found" }
									

								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>



   

</section> */}

      <Modal
        className="appointment-modal"
        show={show}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Header className="head-modal1" closeButton>
          <Modal.Title>Patient Appointment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <h4 className="bold-head">Patient Information</h4>
            <Row className="justify-content-center">
              <Col sm={4}>
                <p>
                  <b>Name: </b> {patient.name}
                </p>
              </Col>
              <Col sm={4}>
                <p>
                  <b>Mobile: </b> {patient.mobile}
                </p>
              </Col>
              <Col sm={4}>
                <p>
                  <b>Address: </b> {patient.present_address}
                </p>
              </Col>
            </Row>
          </Container>

          {/* <Container>
            <h4 className="bold-head mt-3">Doctor Information</h4>
            <Row className="justify-content-center">
              <Col sm={4}>
                <p>
                  <b>Name: </b> {doctor != null ? doctor && doctor.name : ''}
                </p>
              </Col>
              <Col sm={4}>
                <p>
                  <b>Mobile: </b> {doctor != null ? doctor && doctor.phone : ''}
                </p>
              </Col>
              <Col sm={4}>
                <p>
                  <b>Address: </b> {doctor != null ? doctor.address != 'null' ? doctor.address : '' : ''}
                </p>
              </Col>
            </Row>
          </Container> */}


        </Modal.Body>
      </Modal>







      <Modal
        className="appointment-modal"
        show={walletshow}
        onHide={wallethandleClose}
        animation={false}
        centered
      >
        <Modal.Header className="head-modal1" closeButton>
          <Modal.Title>Your Wallet Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {/* <h4 className="bold-head">Patient Information</h4> */}
            <Row className="justify-content-center">
              <Col >
                <h5 style={{color:'red',fontSize:'16'}}>
                  <>Your wallet amount below 500 ! Please recharge your wallet  </>
                </h5>
              </Col>
             
            </Row>
          </Container>

        </Modal.Body>
      </Modal>


    </Dashboard_layout>
  );
};

export default Dashboard;
