import React from 'react'
import Dashboard_layout from '../Layout/Dashboard_layout'
import { useState, useRef } from 'react';
import axios from 'axios';
import "react-step-progress-bar/styles.css";
import useAuth from '../../hooks/useAuth';

import {
    Button,
    Col,
    Container,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    InputGroup,
    Row,
} from "react-bootstrap";
import { ProgressBar, Step } from "react-step-progress-bar";
import { toast, ToastContainer } from "react-toastify";

import OTPInput, { ResendOTP } from "otp-input-react";

import { useEffect } from 'react';
import { Buffer } from 'buffer';

const Abhacard = () => {

    const { user, isAuthenticated } = useAuth();
    const adharref = useRef();

    const [page, setpage] = useState(1);

    const [OTP, setOTP] = useState("");

    const [loadingcard, setloadingcard] = useState(false);


    const [adharprocess, setadharprocess] = useState(false);
    const [adharotpprocess, setadharotpprocess] = useState(false);

    const [adharmobile, setadharmobile] = useState(false);
    const [formprogress, setformprogress] = useState(false);


    const [imageUrl, setImageUrl] = useState(null);



    const [inputdata, setinputdata] = useState({
        fname: "",
        mname: "",
        lname: "",
        hid: "",
        password: "",
        cpassword: "",
        email: "",
    });

    const [OTPnew, setOTPnew] = useState("");

    const [abhanumber, setabhanumber] = useState("");

    const [txnid, settxnid] = useState("");

    const [check, setcheck] = useState(false);

    const [formError, setFormError] = useState("");
    const [usertoken, setusertoken] = useState("");


    // const[stickb,setstickb]=useState();
    const [validphone, setvalidphone] = useState();
    const [adhar, setadhar] = useState('');
    const formdata = (event) => {

        console.log((adharref.current.value).length, 'zazazazazazazaz');
        setadhar(event.target.value);
        if ((adharref.current.value).length == 12) {
            document.getElementById('adharbutton').style.display = 'flex';
        } else {
            document.getElementById('adharbutton').style.display = 'none';
        }
    }
    useEffect(() => {
        if (page == 2) {
            if (OTP.toString().length == 6) {
                document.getElementById('otp_verify').style.display = 'flex';


            } else {

                document.getElementById('otp_verify').style.display = 'none';

            }
        }

    }, [OTP])


    useEffect(() => {
        if (page == 4) {
            if (OTPnew.toString().length == 6) {
                document.getElementById('otp_verifynew').style.display = 'flex';
            } else {
                document.getElementById('otp_verifynew').style.display = 'none';
            }
        }

    }, [OTPnew])
    const formdata_phone = (e) => {

        setvalidphone(e.target.value);
    }
    // console.log(adhar, 'adharadharadhar');

    const handlesubmitadhar = async () => {

        const formdata = new FormData();
        formdata.append("aadhaar", adhar);

        if (check == true) {

            try {
                setadharprocess(true)
                const tokenapi = await axios.get('https://www.lms.extreme.org.in/api/token');
                if (tokenapi.data.status == 1) {
                    setusertoken(tokenapi.data.data);
                    setTimeout(async () => {
                        try {
                            console.log(usertoken, 'usertokenusertokenusertoken');
                            const adharapi = await axios.post("https://www.lms.extreme.org.in/api/generateotp", formdata, {
                                headers: {
                                    authorization: `Bearer ${tokenapi.data.data}`,
                                },
                            });
                            console.log(adharapi, 'adharapiadharapi');

                            if (adharapi.data.status == 1) {
                                settxnid(adharapi.data.data);
                                setpage(2);
                                toast.success(adharapi.data.message);
                                setadharprocess(false)
                            } else {
                                toast.error(adharapi.data.message);
                                setadharprocess(false)

                            }

                        } catch (error) {
                            console.log(error);
                        }
                    }, 2000);
                } else {
                    toast.error(tokenapi.data.message);
                }


            } catch (error) {
                console.log(error);
            }


        }


        else {
            toast.error('Please accept terms and conditions');
        }

    }

    const handleadharotp = async () => {


        try {
            setadharotpprocess(true)
            const apiresult = await axios.post('https://www.lms.extreme.org.in/api/verifyotp', {
                otp: OTP,
                txnId: txnid,
            }, {
                headers: {
                    authorization: `Bearer ${usertoken}`,
                },
            });

            console.log(apiresult, 'apiresultapiresult');
            if (apiresult.data.status == 1) {
                toast.success(apiresult.data.message);
                setpage(3);
                setadharotpprocess(false)

            } else {
                toast.error(apiresult.data.message)
                setadharotpprocess(false)
            }

        } catch (error) {
            console.log(error);
        }

    }
    const handlephone = async () => {

        try {

            setloadingcard(true)
            setadharmobile(true)
            const apiresult = await axios.post('https://www.lms.extreme.org.in/api/linkmobile_number', {
                mobile: validphone,
                txnId: txnid,
            }, {
                headers: {
                    authorization: `Bearer ${usertoken}`,
                },
            });

            console.log(apiresult, 'apiresultapiresult');
            if (apiresult.data.status == 1) {
                toast.success(apiresult.data.message);
                setadharmobile(false)
                setpage(6);
            } else {
                toast.error(apiresult.data.message);
                setadharmobile(false)
            }

        } catch (error) {
            console.log(error);
        }


        const payload = {
            txnId: txnid,
            vle_id: user.id,
            aadharcard:adhar,
            mobile: validphone,
        }
        try {
            const apiresultoken = await axios.post('https://www.lms.extreme.org.in/api/autofilling', payload, {
                headers: {
                    authorization: `Bearer ${usertoken}`,
                },
            });
            try {
                        
                console.log(apiresultoken.data.data.usertoken, '121212121212')

                const apiresult = await axios.get('https://healthidsbx.abdm.gov.in/api/v1/account/getPngCard', {
                    headers: {
                        authorization: `Bearer ${usertoken}`,
                        'X-Token': `Bearer ${apiresultoken.data.data.usertoken}`
                    },
                    responseType: 'blob'
                });

                console.log(apiresult, 'blobtopngresult');

                const scrurl = URL.createObjectURL(apiresult.data);
                document.getElementById('usercard').src = scrurl;
                document.getElementById('anchorbase').href = scrurl;
               
                setImageUrl(URL.createObjectURL(apiresult.data));
                setloadingcard(false)
            } catch (error) {
                console.log(error)
            }


            // setabhanumber(apiresultoken.data.data.usertoken)
        } catch (error) {
            console.log(error)
        }




    }

    const handlemobileotp = async () => {

        // setpage(5);
        try {

            const verifymobileresult = await axios.post('https://www.lms.extreme.org.in/api/verifyotp', {
                otp: OTPnew,
                txnId: txnid
            }, {
                headers: {
                    authorization: `Bearer ${usertoken}`,
                },
            });
            console.log(verifymobileresult, 'mob');
            if (verifymobileresult.data.status == 1) {
                toast.success(verifymobileresult.data.message);
                setpage(5);
            } else {
                toast.error(verifymobileresult.data.message)
            }

        } catch (error) {
            console.log(error)

        }
    }



    // const formFinal_data = (event) => {
    //     const name = event.target.name;
    //     const value = event.target.value;

    //     setinputdata({ ...inputdata, [name]: value })
    // }


    // console.log(inputdata, 'asfadf');


    // const handlefinal = async () => {

    
    //     const payload = {

    //         txnId: txnid,
    //         vle_id: user.id,
    //         aadharcard:adhar
    //     }
    //     try {

    //         setformprogress(true)
    //         const apiresult = await axios.post('https://www.lms.extreme.org.in/api/autofilling', payload, {
    //             headers: {
    //                 authorization: `Bearer ${usertoken}`,

    //             },
    //         });

    //         console.log(apiresult.data.data.usertoken, 'apiresultapiresultautofilling');
    //         if (apiresult.data.status == 1) {

    //             setabhanumber(apiresult.data.data.usertoken)
    //             toast.success(apiresult.data.message);
    //             setformprogress(false)
    //             setpage(6);
    //         } else {
    //             toast.error(apiresult.data.message);
    //             setformprogress(false)
    //         }

    //     } catch (error) {
    //         console.log(error);
    //     }
    //     // }
    // }
    const handleresentotp = async () => {
        try {
            const apiresult = await axios.post('https://www.lms.extreme.org.in/api/resendotp', {
                txnId: txnid
            }, {
                headers: {
                    authorization: `Bearer ${usertoken}`,
                },
            });
            console.log(apiresult, 'apiresultapiresult');
            if (apiresult.data.status == 1) {
                toast.success(apiresult.data.message);

            } else {
                toast.error(apiresult.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleprint = () => {
        window.print()
    }



    const blob = async () => {
        try {
            const apiresult = await axios.get('https://healthidsbx.abdm.gov.in/api/v1/account/getPngCard', {
                headers: {
                    authorization: `Bearer ${usertoken}`,
                    'X-Token': `Bearer ${abhanumber}`
                },
                responseType: 'blob'
            });

            console.log(apiresult, 'blobtopngresult');


            setImageUrl(URL.createObjectURL(apiresult.data));

        } catch (error) {
            console.log(error)
        }



    }

    console.log(imageUrl, 'imageUrlimageUrlimageUrlimageUrlimageUrlimageUrlimageUrl')
    return (
        <Dashboard_layout>
            <ToastContainer />
            <div className="content-top-padding">
                <div className="card shadow mb-4">
                    <div>
                        <div className="abha-number-section">
                            <div className="">
                                <div className="abha-inner">
                                    <div className="abha-heading">
                                        {abhanumber != '' ? (
                                            <>
                                                <div className="button-container" style={{ justifyContent: 'end', margin: 10 }}>
                                                    <button className="btn btn-next" onClick={() => handleprint()}>Print</button>
                                                </div>
                                                <hr></hr>
                                            </>

                                        ) : ''}
                                        <h2>Create ABHA Number</h2>
                                    </div>

                                    <ProgressBar
                                        percent={page == 1 ? 0 : page == 2 ? 25 : page == 3 ? 50 : page == 4 ? 75 : 100}
                                        filledBackground="linear-gradient(to right, #4a99ae, #c8e8f0)"
                                    >
                                        <Step transition="scale">
                                            {({ accomplished }) => (
                                                <span className={page == 1 ? "circle_count" : ''}>1</span>
                                            )}
                                        </Step>
                                        <Step transition="scale">
                                            {({ accomplished }) => (
                                                <span className={page == 2 ? 'circle_count' : ''}>2</span>

                                            )}
                                        </Step>
                                        <Step transition="scale">
                                            {({ accomplished }) => (
                                                <span className={page == 3 ? 'circle_count' : ''}>3</span>

                                            )}
                                        </Step>
                                        <Step transition="scale">
                                            {({ accomplished }) => (
                                                <span className={page == 4 ? 'circle_count' : ''}>4</span>

                                            )}
                                        </Step>
                                        <Step transition="scale">
                                            {({ accomplished }) => (
                                                <span className={page == 4 ? 'circle_count' : ''}>5</span>

                                            )}
                                        </Step>
                                    </ProgressBar>
                                    {page == 1 ? (
                                        <div className="abha-inner-info step-1-section">

                                            <div className="form-group">
                                                <label htmlFor="input1">
                                                    Aadhaar Number 
                                                </label>
                                                <div className="form-input-box">
                                                    {/* <input type="number" className="form-control"  maxlength="2" id="adhar" name="adhar" placeholder="Aadhaar Number" onChange={formdata} /> */}

                                                    <Form.Control
                                                        maxLength={12}
                                                        ref={adharref}
                                                        // type="number" 
                                                        placeholder="Enter Adhar Number"
                                                        onChange={formdata}

                                                        name="adhar"
                                                    />
                                                    {/* <a href="#" className="edit"><i className="fa fa-eye" /></a> */}
                                                </div>
                                                <p style={{ color: 'red' }}>{formError}</p>
                                            </div>
                                            <div className="abha-inner-content">
                                                <p>I, hereby declare that I am voluntarily sharing my Aadhaar Number and demographic information issued by UIDAI, with National Health Authority (NHA) for the sole purpose of creation of ABHA number . I understand that my ABHA number can be used and shared for purposes as may be notified by ABDM from time to time including provision of healthcare services. Further, I am aware that my personal identifiable information (Name, Address, Age, Date of Birth, Gender and Photograph) may be made available to the entities working in the National Digital Health Ecosystem (NDHE) which inter alia includes stakeholders and entities such as healthcare professionals (e.g. doctors), facilities (e.g. hospitals, laboratories) and data fiduciaries (e.g. health programmes), which are registered with or linked to the Ayushman Bharat Digital Mission (ABDM), and various processes there under. I authorize NHA to use my Aadhaar number for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication. I have been duly informed about the option of using other IDs apart from Aadhaar; however, I consciously choose to use Aadhaar number for the purpose of availing benefits across the NDHE. I am aware that my personal identifiable information excluding Aadhaar number / VID number can be used and shared for purposes as mentioned above. I reserve the right to revoke the given consent at any point of time as per provisions of Aadhaar Act and Regulations.</p>
                                            </div>
                                            <div className="rebot-block">
                                                <div className="agree">
                                                    <input type="checkbox" onChange={(e) => setcheck(e.target.checked)} />
                                                    <label>I agree</label>
                                                </div>
                                                <div className="g-recaptcha" data-sitekey="6Ldbdg0TAAAAAI7KAf72Q6uagbWzWecTeBWmrCpJ" />
                                            </div>
                                            <div className="button-container" style={{ display: 'none' }} id="adharbutton">
                                                {/* <div className="btn btn-prev disabled">Back</div> */}
                                                <button className="btn btn-next" disabled={adharprocess == true ? true : false} onClick={() => handlesubmitadhar()}>{adharprocess == true ? 'Processing' : 'Next'}</button>
                                            </div>
                                        </div>

                                    ) : ''}
                                    {page == 2 ? (

                                        <div className="abha-inner-info step-2-section">
                                            <div className="otp-info d-flex justify-content-center text-center">
                                                {/* <p>We Just sent an OTP on the Mobile Number ******3436 linked with your Aadhaar</p> */}
                                                <div className="otp-code-block">
                                                    <p>Enter OTP</p>
                                                    <div className="otp-code-inner">
                                                        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure separator={<span>-</span>}
                                                        />
                                                        <ResendOTP
                                                            style={{ marginTop: 15 }}
                                                            // maxTime={120} 
                                                            onResendClick={() => handleresentotp()} />
                                                        <div className="resend-otp">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="button-container" style={{ display: 'none' }} id="otp_verify">
                                                <button className="btn btn-next" disabled={adharotpprocess == true ? true : false} onClick={() => handleadharotp()} >{adharotpprocess == true ? 'Processing' : 'Next'}</button>
                                            </div>
                                        </div>


                                    ) : ''}
                                    {page == 3 ? (

                                        <div className="abha-inner-info step-4-section">
                                            <div className="profile-detail-info-block">
                                                <div className="profile-detail-heading">
                                                    <p>It is recommended that you see the Mobile Number Linked with Aadhaar.</p>
                                                </div>
                                                <div className="abha-number-inner">
                                                    <div className="form-group">
                                                        <label htmlFor="input1">
                                                            Enter Your Mobile Number
                                                        </label>
                                                        <div className="form-input-box">

                                                            <Form.Control
                                                                maxLength={10}
                                                                // type="number" 
                                                                placeholder="Enter Your Mobile Number"
                                                                onChange={formdata_phone}

                                                                name="phone_2"
                                                            />
                                                        </div>
                                                    </div>
                                                    <p>This mobile number will be used to authenticate your ABHA number</p>
                                                </div>
                                            </div>
                                            <div className="button-container">
                                                <button className="btn btn-next" disabled={adharmobile == true ? true : false} onClick={() => handlephone()}>{adharmobile == true ? 'Processing' : 'Next'}</button>
                                            </div>
                                        </div>



                                    ) : ''}


                                    {page == 4 ? (



                                        <div className="abha-inner-info step-2-section">
                                            <div className="otp-info">
                                                {/* <p>We Just sent an OTP on the Mobile Number ******3436 linked with your Aadhaar</p> */}
                                                <div className="otp-code-block">
                                                    <p>Enter OTP</p>
                                                    <div className="otp-code-inner">

                                                        <OTPInput value={OTPnew} onChange={setOTPnew} autoFocus OTPLength={6} otpType="number" disabled={false} secure />
                                                        <ResendOTP onResendClick={() => console.log("Resend clicked")} />
                                                        <div className="resend-otp">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="button-container" style={{ display: 'none' }} id="otp_verifynew">
                                                <div className="btn btn-next" onClick={() => handlemobileotp()} >Next</div>
                                            </div>
                                        </div>
                                    ) : ''}

                                    {page == 5 ? (
                                        <div className="content-top-padding">
                                            <div className="card shadow mb-4">
                                                <div className="card-header py-3">

                                                </div>
                                                <div className="card-body">
                                                    {/* <Form>

                                                        <Row>
                                                            <Col md="6" sm="6">
                                                                <FormGroup className="mb-3">
                                                                    <label>First Name</label>
                                                                    <InputGroup>
                                                                        <FormControl
                                                                            type="text"
                                                                            name="fname"
                                                                            onChange={formFinal_data}
                                                                            placeholder="First Name"
                                                                            className="form-control"
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="6" sm="6">
                                                                <FormGroup className="mb-3">
                                                                    <label>Middle Name</label>
                                                                    <InputGroup>
                                                                        <FormControl
                                                                            type="text"
                                                                            name="mname"
                                                                            onChange={formFinal_data}

                                                                            placeholder="Middle Name"
                                                                            className="form-control"
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md="6" sm="6">
                                                                <FormGroup className="mb-3">
                                                                    <label>Last Name</label>
                                                                    <InputGroup>
                                                                        <FormControl
                                                                            type="text"
                                                                            name="lname"
                                                                            onChange={formFinal_data}

                                                                            placeholder="Last Name"
                                                                            className="form-control"
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="6" sm="6">
                                                                <FormGroup className="mb-3">
                                                                    <label>Health Id</label>
                                                                    <InputGroup>
                                                                        <FormControl
                                                                            type="text"
                                                                            name="hid"
                                                                            onChange={formFinal_data}

                                                                            placeholder="Health Id"
                                                                            className="form-control"
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md="6" sm="6">
                                                                <FormGroup className="mb-3">
                                                                    <label>E-Mail</label>
                                                                    <InputGroup>
                                                                        <FormControl
                                                                            type="text"
                                                                            name="email"
                                                                            onChange={formFinal_data}

                                                                            placeholder="Enter Email"
                                                                            className="form-control"
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>

                                                        <hr></hr>

                                                        <Row>
                                                            <Col md="6" sm="6">
                                                                <FormGroup className="mb-3">
                                                                    <label>Password</label>
                                                                    <InputGroup>
                                                                        <FormControl
                                                                            type="text"
                                                                            name="password"
                                                                            onChange={formFinal_data}

                                                                            placeholder="Password"
                                                                            className="form-control"
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="6" sm="6">
                                                                <FormGroup className="mb-3">
                                                                    <label>Confirm Password*</label>
                                                                    <InputGroup>
                                                                        <FormControl
                                                                            type="text"
                                                                            name="cpassword"
                                                                            onChange={formFinal_data}

                                                                            placeholder="Confirm Password"
                                                                            className="form-control"
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <div className="text-center">
                                                            <Button
                                                                //   onClick={() => feedsubmit()}
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={handlefinal}

                                                                disabled={formprogress == true ? true : false}
                                                            >
                                                                {formprogress == true ? 'Processing' : 'Submit'}
                                                            </Button>
                                                        </div>
                                                    </Form> */}
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}

                                    {page == 6 ? (


                                        <div className="abha-inner-info step-6-section">

                                            <div className="profile-detail-info-block">

                                                {/* <div className="profile-detail-heading">
                                                    <h2>ABHA number has now been created!</h2>
                                                    <h4>Your ABHA number is {abhanumber.healthIdNumber}</h4>
                                                    <p>Aadhaar Authentication Successful <img src="assets/img/profile-success.png" /></p>
                                                </div> */}


                                                <div className=''>
                                                    <div className="card-header header-body py-3">
                                                        <div className="prescription_headers1">
                                                            <div className="row">
                                                                <div className="col-md-12 text-right printhl">
                                                                       
                                                                            
                                                                              <img
                                                                                src=""
                                                                                alt=""
                                                                                className="img-fluid chamber-img1"
                                                                                id="usercard"
                                                                            />
                                                     
                                                                       {loadingcard ? <h4 style={{textAlign:'center',margin:'auto',padding:'6%'}} id="loading_line" >Loading...</h4> : ''}   

                                                                </div>


                                                      <Button variant="primary" style={{ width: '30%', margin: 'auto' }}> <a style={{ color: 'white' }} id="anchorbase" download href="" target="_blank">download</a></Button>  



                                                                {/* <button onClick={() => blob()}>click me</button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Dashboard_layout>
    )
}

export default Abhacard