import axios from "axios";
const token = localStorage.getItem('token');
const client_ip = localStorage.getItem('client_ip');
console.log(token)

const axiosTrans = axios.create({
  baseURL: "https://api.cognitive.microsofttranslator.com",
  headers: {
    'Ocp-Apim-Subscription-Key':'47a24178918045fba004f1c468848aea',                
    'Ocp-Apim-Subscription-Region':'southeastasia',
    'Content-type':'application/json',  
  },
});
export default axiosTrans;

// wKUd%8P*%469%c1#