import React, { useState, useEffect } from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import useAuth from "../../hooks/useAuth";
import axiosMain from "../../http/axios/axios_main";
import axios from "axios";
import ReactPaginate from "react-paginate";
import helpers from "../../Helper/CryptoHelper";

const Patients_List = () => {
  const { user, isAuthenticated } = useAuth();

  const [patients, setpatients] = useState({
    isloading: false,
    data: [],
    error: "",

  });

  const [patients_data, setpatients_data] = useState("");

  const [vlepatients, setvlepatients] = useState(0);
  const [vleappoitments, setvleappoitments] = useState(0);
  const [vlebalance, setvlebalance] = useState(0);
  const [vleTodayAppointment, setVleTodayAppointment] = useState(0);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 30;
  const pageVisited = pageNumber * usersPerPage;

  const [searchTerm, setsearchTerm] = useState("");

  const getData = async () => {

    try {

      setpatients((prev) => {
        return {
          ...prev,
          isloading: true,
        }
      })

      let inputdata = {
        user_id: user.id,
      };

      let res = await axiosMain.post("/api/vle-patients", helpers.encrypt(JSON.stringify(inputdata)));
      res.data = helpers.decrypt(JSON.stringify(res.data));
      //console.log(res.data.original, "sir data");

      // res=await JSON.parse(res.data.patients)
      //console.log("patient list--n", res.data.original.patient);
      if (res.data.original.status == 1) {
        // setpatients(res.data.patient);

        setpatients({
          isloading: false,
          data: res.data.original.patient,
          error: "",
        });

        // setpatients_data();
      }

      let input_value = {
        user_id: user.id,
      };
      let res_data = await axiosMain.post("/api/vle-dashboard", helpers.encrypt(JSON.stringify(input_value)));
      //console.log(res_data.data, "fine");
      res_data.data = helpers.decrypt(JSON.stringify(res_data.data));
      //console.log(res_data.data.original.data, "fine");
      if (res_data.data.original.status == 1) {
        setvlebalance(res_data.data.original.data.balance);
        setvleappoitments(res_data.data.original.data.appointments);
        setvlepatients(res_data.data.original.data.patients);
        setVleTodayAppointment(res_data.data.original.data.today_appointment);
      }
    } catch (error) {

      setpatients({
        isloading: false,
        data: [],
        error: error.data,
      });
    }

  };

  useEffect(() => {
    getData();
  }, []);


  console.log("patients---", patients);
  const pageCount = Math.ceil(patients.data.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo(0, 0);

  }


  console.log(patients, 'pl');

  return (
    <Dashboard_layout>
      <div className="content-top-padding">
        <div className=" pt-2 pb-4">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i className="fas fa-hospital"></i>
                <span>{vleTodayAppointment}</span>
                <h6>Today's Appointments</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i className="fas fa-user"></i>
                <span>{vlepatients}</span>
                <h6>Total Patients</h6>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i className="fas fa-user"></i>
                <span>{vleappoitments}</span>
                <h6>Total Appointment</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box mb-2">
                <i className="fas fa-repeat-1-alt"></i>
                <span>{vlebalance}</span>
                <h6>Total Balance</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary mt-2">
              Patients List
            </h6>
          </div>
          <div className="card-body">

            <div className="row">
              <div className="col-sm-6">
                {/* <div className="drop-flex">
                  <p>Show </p>
                  
                  <select onChange={formdata}
                    name="pagecount"
                    value={inputdata.pagecount}
                    className="form-select"
                    aria-label="Default select example"
                  >
              
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="300">300</option>
                  </select>

                  <p>entries</p>
                </div> */}
              </div>
              <div className="col-sm-6">
                <div className="drop-flex">
                  <label>Search:</label>
                  <input
                  style={{marginBottom:10}}
                    onChange={(event) => { setsearchTerm(event.target.value) }}
                    type="search"
                    className="form-control input-sm"
                    placeholder="Search by name..."
                    aria-controls="appoinment_table"
                  ></input>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table
                className="table table-bordered vle-table"
                id="dataTable"
                width="100%"
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th>Patient Name</th>
                    <th>Contact</th>
                    <th>Patient Age</th>
                    <th>Doctor</th>
                    <th>Address</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {patients.isloading && (
                    <tr>
                      <td
                        style={{ textAlign: "center", color: "red" }}
                        colSpan="5"
                      >
                        Data loading
                      </td>
                    </tr>
                  )}
                  {!patients.isloading && patients.data &&
                    patients.data?.filter((items) => {
                      if (searchTerm == "") {
                        return items;
                      } else if (items.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return items;
                      }
                    })?.slice(pageVisited, pageVisited + usersPerPage).map((items, key) => {
                      return (
                        <tr>
                          <td>{items.name}</td>
                          <td>{items.mobile}</td>
                          <td>{items.age}</td>
                          <td>{items.doctor}</td>
                          <td>{items.present_address}</td>
                          <td>{items.created_at}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>
    </Dashboard_layout>
  );
};

export default Patients_List;
